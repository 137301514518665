// 德语
export default {
  meau: {
    nav: 'Startseite',
    "nav1": "Mining-Knoten kaufen",
    "nav2": "Orion GPU Race",
    nav3: 'DeepLink-Software',
    nav3_1: 'Software herunterladen',
    nav3_2: 'Softwarelizenz',
    nav4: 'Bandbreiten-Mining',
    "nav5": "Cloud-Internet-Café",
    "nav6": "Blog",
    nav7: 'Docs',
  },
  reward2: {
    desc: {
      title1: 'DeepLink-Knoten',
      text1: 'Nur Node-Besitzer können am CPU-Traffic-Mining und persönlichen GPU-Mining teilnehmen. Die Gesamtbelohnung beträgt 12% der DLC-Token, 4% für CPU-Traffic-Nodes und 8% für persönliche GPU-Nodes. Die Gesamtmenge der Token beträgt 12 Milliarden. Die Produktion pro Node hängt von der tatsächlich verkauften Anzahl der Nodes ab. Wenn alle verkauft werden, produziert jeder Node 100.000 DLC. Wenn nur 10% der Nodes verkauft werden, produziert jeder Node 1 Million DLC.',
      title2: 'Gesamtzahl der Knoten: 120.000 Keine zusätzliche Ausgabe',
      text2: 'Zusätzlicher Airdrop pro Node',
      text3: 'Minimale Menge an DLC, die pro Node abgebaut wird',
      text4: 'Startpreis',
      text5: 'Höchstpreis'
    },
    btn_text: 'Knoten kaufen',
    table: {
      tier: 'Stufe',
      license: 'Node-Lizenzpreis (USDT)',
      total_num: 'Gesamtlizenzen',
      total_usdt: 'Gesamt USDT',
      buy: 'Kaufen'
    },
    intro: {
      title: 'Einführung in das Node Mining',
      text1_1: 'Je mehr Benutzer das DeepLink-Netzwerk hat, desto mehr Netzwerkverkehr und Rechenleistung werden benötigt, was mehr CPU-Traffic-Maschinen und mehr GPU-Rechenmaschinen erfordert, um die Infrastruktur für das DeepLink-Netzwerk bereitzustellen. Dies ermöglicht die Dezentralisierung und hilft DeepLink, sich schneller zu entwickeln. Durch den Kauf einer Node-Lizenz haben Sie die Möglichkeit, am Mining teilzunehmen und Belohnungen zu verdienen. Haben Sie Fragen? Kommen Sie',
      text1_2: 'und',
      text1_3: 'unterhalten Sie sich mit uns oben.',
      text2: 'Jeder Knoten wird in Form eines NFT ausgegeben und erhält zusätzlich einen Airdrop von 30.000 DLC mit BSC-Kette. Der Airdrop beginnt vor der Notierung an der Börse, wobei 20 % des Airdrop-DLC sofort und 80 % über 8 Monate freigeschaltet werden. \n (DLC-Token-Prozess anzeigen: Öffnen Sie die Wallet und wählen Sie das BSC-Netzwerk aus. Wählen Sie „Token hinzufügen“ und geben Sie die Vertragsadresse ein: 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC)',
      text3: 'Nodes können vor dem 31. Oktober 2024 nicht an andere weiterverkauft werden.',
      text4: '25% der abgebauten Token werden sofort freigeschaltet, 75% werden linear über 150 Tage freigeschaltet.',
      text5: 'Wenn das Mining nicht innerhalb von 10 Tagen nach der Listung beginnt, werden die Belohnungen in Form von Airdrops verteilt, die alle 7 Tage stattfinden. Die Airdrop-Regeln sind die gleichen wie die Mining-Ertragsregeln, wobei die tägliche Gesamtproduktion 4,1 Millionen DLC beträgt, die gleichmäßig auf die verkauften Nodes verteilt wird. 25% werden sofort freigeschaltet, 75% werden linear über 150 Tage freigeschaltet. Wenn das Mining bereits begonnen hat, sind entsprechende Maschinen erforderlich, um Belohnungen zu erhalten.',
      text6: 'Bei Käufen von mehr als 20 Knoten kann der Knotenbetrieb delegiert werden. Investoren, die mehr als 1.000 Knoten einzeln kaufen, erhalten umfassenden Support für den Betrieb der Knoten sowie die Möglichkeit, sich an einen kooperativen Betreiber anzudocken, der beim Betrieb der Knoten hilft.',
      text7: 'Eine virtuelle Maschine kann 20 NFT-Knoten unterstützen und das Einkommen wird um das Zwanzigfache multipliziert.',
      text8: 'Kaufen Sie einen DeepLink-Knoten und nehmen Sie am Orion-GPU-Rechenwettbewerb mit einem Preisgeld von 9 Millionen US-Dollar teil: '
    },
    question: {
      ques1: 'Wie viele Node-NFTs werden insgesamt verkauft?',
      ans1: 'Insgesamt 120.000',
      ques2: 'Wie viel kostet jedes Node-NFT?',
      ans2: 'Es ist in 10 Stufen unterteilt, mit Preisen von 30U bis 155U, verkauft auf der offiziellen Website, bis ausverkauft',
      ques3: 'Ist der Kauf eines Node-NFT eine einmalige Zahlung?',
      ans3: 'Ja, der Kauf eines Nodes ist eine einmalige Zahlung, es sind keine zusätzlichen Gebühren in der Zukunft erforderlich',
      ques4: 'Gibt es zusätzliche Airdrops beim Kauf eines Node-NFTs?',
      ans4: 'Ja, jeder Node-Besitzer erhält einen Airdrop von 30.000 DLC. Der Airdrop beginnt vor der Listung auf der Börse, wobei 20% der DLCs sofort freigeschaltet werden und 80% über 8 Monate freigeschaltet werden',
      ques5: 'Was ist der spezifische Mining-Mechanismus?',
      ans5: 'Die Gesamtbelohnung beträgt 12 Milliarden DLC. Die Gesamteinnahmen für Traffic-Nodes betragen 4 Milliarden DLC, und die Gesamteinnahmen für persönliche GPU-Maschinen-Nodes betragen 8 Milliarden DLC. Durch den Kauf eines Node-NFTs können Sie gleichzeitig an beiden Mining-Methoden teilnehmen. Die Belohnung für die ersten 4 Jahre beträgt 1,5 Milliarden DLC pro Jahr, mit einer Halbierung alle 4 Jahre. Die Gesamtbelohnung pro Tag beträgt 4,11 Millionen DLC, die nur an Node-NFT-Besitzer verteilt wird. 25% der abgebauten Token werden sofort freigeschaltet, 75% werden linear über 150 Tage freigeschaltet',
      ques6: 'Kann ein gekauftes Node-NFT gleichzeitig am CPU-Traffic-Mining und am persönlichen GPU-Mining teilnehmen?',
      ans6: 'Ja',
      ques7: 'Was ist ein CPU-Bandbreiten-Traffic-Node?',
      ans7: 'Der CPU-Bandbreiten-Traffic-Node übernimmt die Kernaufgabe der Berechnung und Weiterleitung des Traffics im DeepLink-Netzwerk. Durch das Mining-Modell ermöglicht DeepLink es mehr Menschen, teilzunehmen und Belohnungen zu verdienen. Die Gesamteinnahmen für Traffic-Nodes betragen 4 Milliarden DLC',
      ques8: 'Wie hoch ist die Gesamtbelohnung für Bandbreiten-Traffic-Nodes im ersten Jahr?',
      ans8: 'Die Belohnung im ersten Jahr beträgt 500 Millionen DLC, mit einer täglichen Belohnung von etwa 1,37 Millionen DLC',
      ques9: 'Wie steht die Bandbreite eines jeden CPU-Bandbreiten-Traffic-Nodes im Verhältnis zu seinen Einnahmen?',
      ans9: 'Die Basisbandbreite beträgt 10Mb Uplink-Bandbreite. Der Bandbreitenwert beträgt 10 bei 10Mb Uplink-Bandbreite. Die Bandbreite ist nur in Vielfachen von 10 gültig, zum Beispiel wird eine Uplink-Bandbreite von 45Mb als 40 im Bandbreitenwert berechnet. Die Bandbreite erhöht sich um 30% entsprechend der tatsächlichen Nutzung. Schließlich werden die Einnahmen entsprechend dem tatsächlichen Bandbreitenwert aufgeteilt',
      ques10: 'Sind die Konfigurationsanforderungen für Bandbreiten-Traffic-Nodes hoch? Gibt es Konfigurationsinformationen? Wie kann man am Mining teilnehmen?',
      ans10: 'Die Konfigurationsanforderungen sind nicht hoch. vCPU 1C + 2G Speicher + 50G Festplatte. Die Miete bei AWS kostet etwa 10 USD pro Monat, die Bandbreite wird nach tatsächlichem Verbrauch abgerechnet. Nachdem Sie eine CPU-VM bei AWS gemietet haben, installieren Sie das DeepLink-Traffic-Node-Mining-Programm, erstellen Sie eine Wallet-Adresse, übertragen Sie das Node-NFT in das Wallet und aktivieren Sie die Mining-Funktion, um am Mining teilzunehmen',
      ques11: 'Was ist ein persönlicher GPU-Maschinen-Node?',
      ans11: 'Individuen können ihre GPU-Computer anbieten, um am Mining teilzunehmen. Die GPU-Maschinen stellen Cloud-Gaming-Infrastrukturdienste für das DeepLink-Protokoll bereit. Die Gesamteinnahmen für persönliche GPU-Maschinen-Nodes betragen 8 Milliarden DLC',
      ques12: 'Wie hoch ist die Gesamtbelohnung für persönliche GPU-Maschinen-Nodes im ersten Jahr?',
      ans12: 'Die Belohnung im ersten Jahr beträgt 1 Milliarde DLC, mit einer täglichen Belohnung von etwa 2,74 Millionen DLC',
      ques13: 'Wie sind die Konfigurationsanforderungen für persönliche GPU-Maschinen-Nodes? Wie kann man am Mining teilnehmen?',
      ans13: 'Die Konfigurationsanforderungen für GPU-Maschinen sind: CPU-Frequenz von mindestens 3,0 GHz, GPU aus der Nvidia Geforce-Serie: 20xx, 30xx, 40xx, und mindestens 16G Speicher'
    }
  },
  BandWidth: {
    "title": "Regeln für das Mining von Datenverkehrsbandbreite",
    "text1": "Beim Mining von Datenverkehrsbandbreite gibt es insgesamt 4 Milliarden DLC als Belohnung, mit 500 Millionen DLC als jährlicher Belohnung in den ersten vier Jahren, die alle vier Jahre halbiert werden.",
    "cont2": {
      "title": "Teilnahmebedingungen",
      "text1_1": "Es ist erforderlich, Node NFT zu besitzen (jeder Knoten gewährt einen Airdrop von 30.000 DLC, im Wert von 90$, erfahren Sie mehr über Knoten: ",
      "text1_2": "). Eine einzelne CPU-Maschine kann mehrere Knoten halten, und die Belohnung in Token verhält sich proportional zur Anzahl der Knoten. Die maximale Anzahl an Node NFTs, die eine einzelne Maschine halten kann, beträgt 20.",
      "text2_1": "Mindestanforderungen für CPU-Server: \n Die Parameter des Rechnungsservers müssen die Mindestanforderungen erfüllen",
      "text3_1": "CPU",
      "text3_2": "vCPU 1C",
      "text4_1": "Speicher",
      "text4_2": "2G",
      "text5_1": "Festplatte:",
      "text5_2": "50G Festplatte",
      "text6_1": "Netzwerkbandbreite:",
      "text6_2": "Die Grundbandbreite beträgt 10Mb Upload; es wird empfohlen, für jeden Zuwachs von 200M Bandbreite 1C CPU und 2G Speicher hinzuzufügen"
    },
    cont3: {
      title: 'Regelungen für den Datenverkehrsbereich',
      text: 'Das Datenverkehrsmining wird weltweit in etwa 130 Regionen aufgeteilt, jeder Region wird eine unterschiedliche Gesamtzahl an DLC-Belohnungen zugewiesen, die Formel für die regionalen Belohnungen lautet: Regionale Belohnungen = Regionalwert * Tagesbelohnung / Gesamtregionalwert',
      text1: 'Die Liste der Regionen ist wie folgt:',
      text2: 'Asien',
      text3: 'Amerika',
      text4: 'Europa',
      text5: 'Afrika',
      text6: 'Ozeanien',
      li_other: 'Andere',
      li1: 'China',
      li1_1: 'Nordchina',
      li1_2: 'Nordostchina',
      li1_3: 'Ostchina',
      li1_4: 'Zentralchina',
      li1_5: 'Südchina',
      li1_6: 'Südwestchina',
      li1_7: 'Nordwestchina',
      li1_8: 'Taiwan, China',
      li1_9: 'Hongkong, China',
      
      li2: 'Indien',
      li2_1: 'Uttar Pradesh',
      li2_2: 'Maharashtra',
      li2_3: 'Bihar',
      li2_4: 'Indonesien',
      li2_5: 'Pakistan',
      li2_6: 'Bangladesch',
      li2_7: 'Japan',
      li2_8: 'Philippinen',
      li2_9: 'Vietnam',
      li2_10: 'Türkei',
      li2_11: 'Thailand',
      li2_12: 'Südkorea',
      li2_13: 'Malaysia',
      li2_14: 'Saudi-Arabien',
      li2_15: 'Vereinigte Arabische Emirate',

      li3: 'Vereinigte Staaten',
      li3_1: 'Kalifornien',
      li3_2: 'Texas',
      li3_3: 'Florida',
      li3_4: 'New York',
      li3_5: 'Pennsylvania',
      li3_6: 'Illinois',
      li3_7: 'Ohio',
      li3_8: 'Georgia',
      li3_9: 'Michigan',
      li3_10: 'Nordkarolina',
      li3_11: 'Andere Regionen der USA',
      
      li4_1: 'Mexiko',
      li4_2: 'Kanada',
      li4_3: 'Brasilien',
      li4_4: 'Kolumbien',
      li4_5: 'Argentinien',
      
      li5: 'Russland',
      li5_1: 'Moskau',
      li5_2: 'Sankt Petersburg',
      li5_3: 'Andere Gebiete in Russland',
      li5_4: 'Deutschland',
      li5_5: 'Vereinigtes Königreich',
      li5_6: 'Frankreich',
      li5_7: 'Italien',
      li5_8: 'Spanien',
      li5_9: 'Niederlande',
      li5_10: 'Schweiz',
      
      li6_1: 'Nigeria',
      li6_2: 'Ägypten',
      li6_3: 'Südafrika',
      
      li7_1: 'Australien',
    },
    cont5: {
      title: 'IV. Belohnungsmechanismus (basiert auf der DBC AI öffentlichen Kette mit EVM-kompatiblen DLC, Browser-URL: https://test.dbcscan.io/)',
      text1: 'In den ersten vier Jahren beträgt die jährliche Mining-Belohnung 500 Millionen DLC, täglich 1,369 Millionen DLC. Jede Region erhält DLC-Belohnungen basierend auf Gewichtungsanteilen. Wenn eine Region keine Maschinen hat, werden die DLC-Belohnungen dieser Region automatisch vernichtet und niemandem zugeteilt.',
      text2: 'Die gesamte täglich pro Region vergebene Token-Menge in diesem System ist festgelegt. Die Belohnung einer Maschine hängt von der Bandbreite der Maschine, der effektiven Mining-Zeit Y und der Anzahl der eingesetzten Token ab. Je mehr Token eingesetzt werden, desto größer ist die Belohnung, jedoch wächst sie in einem nicht-linearen Verhältnis. Die Belohnungsbasis ist für 0-10.000 Münzen gleich; Belohnungen korrelieren mit einer Funktion, wenn sie 10.000 Münzen übersteigen.',
      text3: 'Wenn die Verkehrsbandbreite der Maschine genutzt wird, steigt der Rechenleistungswert um 30%, und die Anzahl der Node-NFTs ist ein Multiplikator des Rechenleistungswerts. Derzeit kann jede Maschine bis zu 20 Node-NFTs halten.',
      text4: 'Nur Maschinen mit Node-NFTs sind für Online-Belohnungen berechtigt.',
      text5: 'Derzeit kann eine einzelne Maschine bis zu 20 Node-NFTs binden, wobei mehr NFTs zu mehr Belohnungen führen, was eine lineare Beziehung zeigt.',
      text6: 'In den ersten 300 Tagen kann eine einzelne Maschine bis zu 20 Node-NFTs binden.',
      text7: 'In den ersten 600 Tagen kann eine einzelne Maschine bis zu 10 Node-NFTs binden.',
      text8: 'Von 600 bis 900 Tagen kann jede Maschine bis zu 5 Node-NFTs binden.',
      text9: 'Von 900 bis 1200 Tagen kann jede Maschine bis zu 2 Node-NFTs binden.',
      text10: 'Von 1200 bis 1800 Tagen kann jede Maschine bis zu 1 Node-NFT binden.',
      text11: 'Nach 1800 Tagen kann jedes Node-NFT 2 Maschinen binden.',
      text12: 'Nach 2400 Tagen kann jedes Node-NFT 4 Maschinen binden.',
      text13: 'Berechnung der Belohnungen:',
      text14: 'R: Gesamtmenge der täglichen Belohnungs-Token (fester Wert)',
      text15: 'Die Anzahl der eingesetzten Token der i-ten Maschine',
      text16: 'Der Rechenleistungswert der i-ten Maschine',
      text17: 'Die effektive Mining-Zeit der i-ten Maschine',

      text18: 'Wobei N die Gesamtzahl der am Mining beteiligten Maschinen ist',
      text19: 'Die Belohnung für 100.000 Münzen ist 1,23026-mal so hoch wie die für 10.000 Münzen, eine Steigerung um etwa 23,03%',
      text20: 'Die Belohnung für 1 Million Münzen ist 1,46052-mal so hoch wie die für 10.000 Münzen, eine Steigerung um etwa 46,05%',
      text21: 'Die Belohnung für das Einsetzen von 10 Millionen Münzen ist 1,69078-mal so hoch wie die für 10.000 Münzen, eine Steigerung um etwa 69,08%',
      text22: 'Die Belohnung für das Einsetzen von 100 Millionen Münzen ist 1,92103-mal so hoch wie die für 10.000 Münzen, eine Steigerung um etwa 92,10%',
      
      title1: 'Belohnungsauszahlung:',
      text1_1: 'Belohnungen können einmal alle 24 Stunden beansprucht werden',
      text1_2: 'Wenn anfangs kein DLC eingesetzt wurde oder die eingesetzte Menge weniger als 10.000 beträgt, werden die Einnahmen als eingesetzter DLC akkumuliert, bis 10.000 DLC erreicht sind',
      text1_3: '1) Mieteinnahmen werden als eingesetztes Kapital akkumuliert, bis 10.000 DLC erreicht sind',
      text1_4: '2) Die Mindestmietdauer beträgt 2 Stunden, es gibt keine maximale Mietdauer; wenn weniger als eine Stunde nutzbare Laufzeit verbleibt, kann die Maschine nicht vermietet werden und zählt nicht zu den Online-Belohnungen. Bergleute können die Laufzeit jederzeit verlängern, müssen jedoch mindestens 2 Stunden hinzufügen',
      
      title2: 'Tägliche DLC-Einkommensberechnung für Maschinen: DLC wird zu einem Preis von 0,003U berechnet,',
      text2_1: 'Die Bandbreite meiner CPU-Maschine:',
      text2_2: 'Anzahl der Knoten-NFTs:',
      text2_3: 'Region:',
      text2_4: 'Anzahl der eingesetzten DLC:',
      text2_5: 'Gesamte Bandbreite:',
      text2_6: '(Berechnung basierend auf durchschnittlich 10 NFTs und durchschnittlich 10.000 eingesetzten DLC)',
      text2_7: 'Bitte auswählen',
      title3: 'Einkommen aus On-Chain-Belohnungen: {dlc_num} DLC (entspricht {usdt_num} USD zum aktuellen DLC-Preis: 0,003 USD)',
      title4: 'Meldungs- und Verifizierungsmechanismus (Basierend auf der EVM-kompatiblen DLC der DBC AI öffentlichen Kette)',
      text4_1: 'Die Maschine verfügt dauerhaft über einen Erkennungsmechanismus der DBC AI öffentlichen Kette, der Informationen an den verteilten Erkennungscluster von DBC meldet, um festzustellen, ob die Maschine online ist; falls nicht, werden Online-Belohnungen abgezogen. Wenn während der Nutzung durch den Benutzer eine Unterbrechung auftritt, wird dem Benutzer DLC abgezogen. Grenze der Strafe:',
      text4_2: '1) Wenn keine Münzen eingesetzt sind, werden nur angesammelte Belohnungen bestraft, bis zu 10.000 DLC',
      text4_3: '2) Wenn DLC eingesetzt sind, können bis zu 10.000 DLC bestraft werden',
    }
  },
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: 'Dezentralisiertes AI Cloud Gaming-Protokoll',
      title2: 'Aufbau einer Infrastruktur für ultra-niedrige Latenz bei der Cloud-Gaming-Rendering',
      btn1: 'PinkSale betreten',
      btn2: 'Whitepaper herunterladen',
      btn3: 'Pitch Deck herunterladen',
      btn4: 'DeepLink Public Sale',
      btn5: 'Willkommen bei Node Rewards',
      btn6: 'Client herunterladen',
      desc1: 'Unterstützt DeepLink zur Erlangung von DLC Token',
      desc2: '100% Sicher & Verifiziert',
      desc3: 'NVIDIA Inception Program'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: 'Die angezeigte Effizienz ist ein Beispiel von zwei Computern, die 50 km entfernt sind. Der Steuercomputer ist ein gewöhnliches Laptop, der gesteuerte Computer ist eine leistungsstarke GPU-Maschine. Es wird ein dynamisches Spielbild angezeigt, das etwa 3-5 Sekunden dauert.',
      btn: 'Video abspielen, um mehr zu erfahren'
    },
    cont3: {
      text1: 'Ultra-niedrige Latenz',
      text2: 'Hohe Auflösung',
      text3: 'Ultra-niedrige Latenz',
      text4: 'Kein Ruckeln, keine Verbindungsabbrüche, hohe Stabilität',
      desc1: 'DeepLink Protocol erreicht einen revolutionären technologischen Durchbruch und bietet ein unvergleichliches, perfektes Erlebnis.',
      desc2: 'Die Mitglieder des Kerntechnologie-Teams sind seit 2011 in der Cloud-Gaming-Branche tätig und stammen von Unternehmen wie Cisco, Intel, Nvidia, Huawei, ShunNet und anderen. Nach über 10 Jahren Forschung und Entwicklung wurde DeepLink geboren.'
    },
    cont16: {
      title1_1: 'DeepLink Protocol bietet ultra-niedrige Latenztechnologie für Cloud-Gaming-Dienste',
      title1_2: 'Technologische Dienste mit ultra-niedriger Latenz für Cloud-Gaming',
      title2: 'Aktuell basieren die folgenden Produkte auf dem DeepLink Protocol, und mehr als 20 weitere Anwendungen sind in der Entwicklung',
      text1: 'Fernsteuerung',
      text2: 'Cloud-Gaming & Cloud-Gaming-Plattformen',
      text3: 'Cloud-Internetcafés',
      text4: 'Cloud-XR',
      text5: 'XXX Cloud-VR-Games',
      text6: 'YYY Cloud-Gaming-Plattform'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: 'Verwendung von Fernsteuerungstools als Einstiegspunkt für den Traffic',
      title2: 'Die DeepLink-Software verwendet Fernsteuerungstools als Einstiegspunkt für den Traffic und Cloud-Gaming-Plattformen sowie Cloud-Internetcafés als Monetarisierungsinstrumente',
      text1_1: '900,000+',
      text1_2: 'Monatlich aktive Benutzer',
      text2_1: '110+',
      text2_2: 'Anzahl der Länder, in denen Dienste angeboten werden',
      text3_1: '2,500,000+',
      text3_2: 'Gesamtzahl der Benutzer',
      text4_1: '300,000+',
      text4_2: 'Täglich aktive Benutzer',
      desc1: 'Fernsteuerungstools',
      desc2: 'Cloud-Gaming und Cloud XR',
      desc3: 'Cloud-Internetcafés',
      btn1: 'YouTube KOL Stufe 1',
      btn2: 'YouTube KOL Stufe 2',
      btn3: 'YouTube KOL Stufe 3',
      btn4: 'YouTube KOL Stufe 4'
    },
    cont17: {
      title1: 'Geschäftsmodell von DeepLink Protocol',
      text1: 'Provision-Modell',
      text2: 'Lizenz-Modell',
      desc1: 'Für jede Cloud-Gaming-Transaktion erhebt DeepLink Protocol eine Provision von 20-30%, die Provision wird in DLC bezahlt und 100% DLC wird sofort vernichtet.',
      desc2: 'Für Anwendungen wie Cloud-Internetcafés und Cloud-E-Sport-Hotels zahlt jede Anwendung für jedes Computer-Terminal eine monatliche Lizenzgebühr von 6 US-Dollar.'
    },
    cont5: {
      title1: 'Bereitstellung von ungenutztem Netzwerkverkehr durch Bergbau mittels Blockchain-Technologie',
      desc1: 'Node-Verbindung',
      desc2_1: 'Benötigte Geräte',
      desc2_2: 'Traffic-Weiterleitung',
      desc3: 'Miner weltweit können NFT kaufen, um Traffic-Knoten bereitzustellen und DLC-Token-Belohnungen zu erhalten',
      desc3_2: '',
      title2: 'Traffic-Node-Anbieter',
      text2: 'Miner stellen Traffic-Knoten bereit', 
      title3: 'Nutzerseite',
      text3: 'Einzelne Nutzer'
    },
    cont6: {
      title1: 'Deutliche Steigerung der Wettbewerbsfähigkeit von DeepLink auf dem Markt',
      title2: 'Durch Blockchain-Technologie',
      desc1: 'Durch Smart Contracts können digitale Währungen gestaked werden, um die stabile Bereitstellung von Dienstleistungen durch GPU-Anbieter zu garantieren',
      desc2: 'Jeder kann zur Netzwerkbeiträge leisten und Belohnungen erhalten, z. B. durch Bereitstellung von GPU-Servern oder Traffic-Knoten',
      desc3: 'Die Einführung von Minern, die Traffic-Weiterleitungsknoten bereitstellen, ermöglicht eine breite Verteilung der Knoten, ohne dass ein zentralisiertes Unternehmen Maschinen zur Bereitstellung von Knoten einsetzen muss, was das System dezentraler macht und das Unternehmen keine Serverkosten hat',
      desc4: 'Durch Smart Contracts und das Staken von digitalen Währungen können Investoren innerhalb von ca. 1 Jahr aussteigen und eine Rendite erzielen, ohne 5-10 Jahre warten zu müssen',
      desc5: 'Nutzer können an den Dividenden des Projekts teilhaben, was die Verbreitung fördert',
      desc6: 'Nutzer können grenzenlose und freie Zahlungen durchführen',
      text1: 'Staken',
      text2: 'Jeder kann teilnehmen',
      text3: 'Null Kosten',
      text4: 'Verkürzte Rückgewinnungszeit',
      text5: 'Grenzenlos',
      text6: 'Nutzer erhalten Dividenden'
    },
    cont7: {
      title1: 'Wir führen einen Dimensionsangriff der Web3-Technologie auf die Web2-Technologie durch',
      desc1: 'Um ein gutes Erlebnis mit geringer Latenz im Cloud-Gaming zu erreichen, benötigen GPU-Maschinen neben der Technologie eine Nähe von bis zu 50 Kilometern zum Benutzer, und eine globale Abdeckung erfordert mehr als 50.000 Datenzentren.',
      title2: 'Traditionelle zentralisierte Cloud-Gaming-Plattform',
      title3: 'DeepLink basierend auf Blockchain-Technologie Cloud-Gaming-Plattform',
      text1: 'Die Plattform kauft selbst Maschinen, was enorme Kosten verursacht und eine ungewisse Rentabilität mit sich bringt.',
      text2: 'Die Maschinenabdeckung ist eng und eine globale Abdeckung ist nicht möglich',
      text3: 'Zentralisierte Technologie kann Zehntausende von Rechenzentrumsverbindungen nicht unterstützen',
      text4: 'Miner und Besitzer von Internetcafés stellen GPU-Server zur Verfügung, und es gibt Token-Belohnungen für Miner, damit sie ihre Kosten schnell zurückzahlen können, und die Plattform steht nicht unter Druck, Maschinen zu kaufen',
      text5: 'Es gibt viele Miner und Internetcafés, und sie können weltweit verbreitet werden',
      text6: 'Die verteilte Blockchain-Technologie unterstützt mehr als 100.000 Rechenzentren und mehr als 10 Millionen GPU-Server-Bookpoints'
    },
    cont8: {
      title1: 'Anwendungsszenarien für DeepLink Token',
      text1: 'DLC kaufen',
      text2: 'DLC bezahlen', 
      text3: 'USDT bezahlen',
      text4: 'DLC bezahlen',
      text5: 'DLC und USDT in DEX einspeisen', 
      text6: 'DLC verkaufen',
      text7: 'Benutzer 1',
      text8: 'DeepLink Software', 
      text9: 'Davon werden 40%DLC direkt auf der Blockchain vernichtet',
      text10: '1. NFT kaufen',
      text11: '2. Cloud-Spielzeit kaufen', 
      text12: '3. Internetcafé-Maschinenzeit mieten',
      text13: '4. Persönliche geteilte Spielzeit kaufen', 
      text14: 'Davon werden 40%USDT auf DEX zum Rückkauf von DLC verwendet und dann vernichtet', 
      text15: 'NFT kaufen',
      text16: 'DEX und CEX',
      text17: 'Andere Handelsplattformen',
      text18: 'Miner',
      text19: 'Liquiditätsmining-Belohnungen',
      text20: 'Belohnungen für 2 Jahre, jedes Jahr 5 Milliarden DLC'
    },
    cont8_new: {
      text7: 'Private Nutzer',
      text12: '3. Mietzeit für Maschinen im Internetcafé',
      text21: 'Geschäftskunden',
      text22: 'DeepLink Protokoll',
      text23: '1. Lizenz kaufen',
      text24: '2. 20-30% Gebühren',
      text25: '100% der DLCs werden direkt auf der Blockchain zerstört'
    },
    cont8_new1: {
      title: 'Top YouTubers unterstützen unser Projekt',
      text1: '',
      text2: 'Geschäftsnutzer',
      text3: 'DeepLink Protokoll',
      text4: '1. Lizenz kaufen',
    },
    cont9: {
      title1: 'DeepLink Token Liquiditätsmining-Belohnungen',
      text1: 'Benutzer 1',
      text2: 'Einspeisung von DLC und USDT in DEX', 
      text3: 'Liquiditätsmining-Belohnungen',
      text4: 'Belohnungen über 2 Jahre, jedes Jahr 5 Milliarden DLC',
      text5: 'Einspeisung von DLC und USDT Liquidität in Uniswap und Pancake Swap', 
      text6: 'Tägliche Vergabe von Belohnungen, automatischer Erhalt durch Liquiditäts-NFT',
      text7: '80% der Belohnungen gehen an die Liquiditätsanbieter, 20% an die Trader',
      text8: 'Tägliche Belohnung von 13,698,630 DLC'
    },
    cont9_new: {
      title1: 'DeepLink Token Liquiditätsmining-Belohnungen',
      text1: '5% DLC sowie entsprechende Beträge von USDT, ETH und BNB.',
      text2: 'Injektion in DEX',
      text3: 'Steigerung der DEX-Liquidität',
      text4: '',
      text5: 'Diese 5% der Tokens werden während des IDO (Initial DEX Offering) verkauft. Basierend auf dem tatsächlichen Verkaufsanteil werden letztendlich alle in den DEX eingespeist.',
      text6: 'Einspeisung der DLC- und USDT-Liquidität in DEX wie Uniswap, SushiSwap, Pancake Swap',
      text7: 'Diese Liquidität wird für 5 Jahre gesperrt und anschließend zur langfristigen Entwicklung des Projekts verwendet.',
      text8: ''
    },
    cont10: {
      title: 'Fahrplan & Pläne',
      desc1: 'Oktober 2020',
      text1: 'Start des DeepLink-Projekts',
      desc2: 'März 2021',
      text2: 'Erhalt von Seed-Investitionen',
      desc3: 'August 2021',
      text3: 'Veröffentlichung der ersten internen Entwicklerversion von DeepLink, technische Verzögerung erreicht 3ms, Unterstützung für Gaming-Tastaturen und -Mäuse',
      desc4: 'Mai 2022',
      text4: 'Veröffentlichung der ersten Testversion der DeepLink-Fernsteuerungsfunktion, Unterstützung für 3K 144HZ-Anzeige',
      desc5: 'August 2022',
      text5: 'Veröffentlichung der zweiten Testversion der DeepLink-Fernsteuerungsfunktion, Unterstützung für virtuelle Displays',
      desc6: 'Oktober 2022',
      text6: 'Markteinführung der offiziellen Version von DeepLink, Unterstützung für Koreanisch, Chinesisch und Englisch, Gründung der Firma DeepCloudX in Singapur zur Verwaltung von DeepLink',
      desc7: 'Dezember 2022',
      text7: 'Erhalt von Angel-Investitionen',
      desc8: 'April 2023',
      text8: 'Kooperation mit der größten Internetcafé-Kette in Korea, Aufbau eines echten Musterzimmers im Café, Durchführung von Tests für Cloud-Internetcafés, positive Rückmeldungen von Nutzern',
      desc9: 'Juni 2023',
      text9: 'DeepLink-Downloads überschreiten 200.000, Nutzer in über 100 Ländern, Unterstützung für 8K 60Hz-Anzeige',
      desc10: 'Januar 2021',
      text10: 'Festlegung der Forschungs- und Entwicklungsrichtung von DeepLink und Beginn der Produktgestaltung und -entwicklung',
      desc11: 'April 2021',
      text11: 'Das Kernteam ist seit 2011 in der Cloud-Gaming-Branche tätig, darunter Gründungsteams von Unternehmen wie Cisco, IntelNvidia, Huawei und Shunwang',
      desc12: 'März 2022',
      text12: 'DeepLink begann mit Tests im Bereich Internetcafés und die technische Verzögerung betrug 1 ms',
      desc13: 'Juli 2022',
      text13: 'Veröffentlicht die dritte Testversion der DeepLink-Fernsteuerungsfunktion, die den Datenschutzbildschirm und das Fernkopieren von Dateien unterstützt',
      desc14: 'September 2022',
      text14: 'Die vierte Testversion der DeepLink-Fernbedienungsfunktion wird veröffentlicht und unterstützt Mehrkanal-Streaming und 444 True Color',
      desc15: 'November 2022',
      text15: 'Bauen Sie 10 Mini-PC-Maschinen und zeigen Sie eine simulierte Internetcafé-Umgebung in Seoul zum Testen von Cloud-Internetcafés',
      desc16: 'Februar 2023',
      text16: 'DeepLink hat 100.000 Downloads überschritten, Benutzer sind in 50 Ländern und unterstützt 4K-200-Hz-Anzeige',
      desc17: 'Mai 2023',
      text17: 'DeepLink hat 150.000 Downloads überschritten, Benutzer decken 80 Länder ab, unterstützt DLC-Wallet-Funktion, unterstützt Vietnamesisch und Japanisch'
    },
    cont11: {
      title: 'Entwicklungsplan',
      desc1: 'Juli 2023',
      text1: 'Unterstützung für das Hinzufügen von Gerätelisten und die Freigabe von Belohnungsfunktionen',
      desc2: 'August 2023',
      text2: 'Start des ersten Cloud-Internetcafés in Seoul',
      desc3: 'Oktober 2023',
      text3: 'Unterstützung für Freigabe von Belohnungsfunktionen',
      desc4: 'Dezember 2023',
      text4: 'Unterstützung für NFT-Funktionen',
      desc5: '1. Quartal 2024',
      text5: 'Unterstützung für die Spielfreigabefunktion für die WEB-Version und Aufbau von Partnerschaften, Ziel ist es, die Downloadzahl von DeepLink auf über 500.000 zu steigern',
      desc6: '2. Quartal 2024',
      text6: 'Ziel ist es, die Downloadzahl von DeepLink auf über 1.000.000 zu steigern und mit 10 Cloud-Internetcafés zusammenzuarbeiten',
      desc7: '3. Quartal 2024',
      text7: 'Ziel ist es, über 2 Millionen Downloads für DeepLink zu erreichen und Partnerschaften mit 30 Cloud-Internetcafés einzugehen',
      desc8: '4. Quartal 2024',
      text8: 'Ziel ist es, über 2,5 Millionen Downloads für DeepLink zu erreichen und Partnerschaften mit 40 Cloud-Internetcafés einzugehen',
      
      desc9: 'Q1 2025',
      text9_1: 'Game AI Agent Virtueller Charakter: Entwickeln Sie einen virtuellen Charakter, der jederzeit aktiviert werden kann',
      text9_2: 'Ermöglichen Sie dem AI-Agenten, die Benutzeroberfläche des Spiels in Echtzeit zu erkennen und gezielte Spielanweisungen zu geben',
      text9_3: 'Erweiterung der Internet-Café-Funktion: Unterstützung der Vermietung ungenutzter Maschinen in Internet-Cafés, um die Ressourcennutzung zu ermöglichen',
      text9_4: 'Cloud-Gaming-Unterstützung: Erste Implementierung der Cloud-Gaming-Funktionalität für eine effiziente und bequeme Benutzererfahrung.',
      text9_5: 'Meilensteine:',
      text9_6: 'Über 3 Millionen Nutzer',
      text9_7: 'Geteilte GPUs über 1000',
      text9_8: 'Partnerschaften mit über 60 Cloud-Internet-Cafés',
      desc10: 'Q2 2025',
      text10_1: 'Sprachsteuerung: Implementierung von Sprachbefehlen, mit denen Benutzer den AI-Agenten bei der Durchführung von Spieloperationen anleiten können, um die Interaktionseffizienz zu verbessern',
      text10_2: 'Verbesserte Internet-Café-Funktionalität: Optimierung der Vermietungsfunktion von ungenutzten Maschinen in Internet-Cafés zur Verbesserung der Ressourcennutzung und Stabilität',
      text10_3: 'Cloud-Gaming-Optimierung: Verbesserung der Cloud-Gaming-Leistung, Reduzierung der Latenz und Verbesserung der Ressourceneffizienz',
      text10_4: 'Meilensteine:',
      text10_5: 'Über 4 Millionen Nutzer',
      text10_6: 'Geteilte GPUs über 2000',
      text10_7: 'Partnerschaften mit über 100 Cloud-Internet-Cafés',
      desc11: 'Q3 2025',
      text11_1: 'AI-Agent Spielbetrieb: Ermöglichen Sie dem AI-Agenten, Benutzern bei grundlegenden Spieloperationen wie Aufgabenführung und Ressourcensammlung proaktiv zu helfen',
      text11_2: 'Meilensteine:',
      text11_3: 'Über 5 Millionen Nutzer',
      text11_4: 'Geteilte GPUs über 3000',
      text11_5: 'Partnerschaften mit über 150 Cloud-Internet-Cafés',
      desc12: 'Q4 2025',
      text12_1: 'AI-Agenten mittlere Spieloperationen: Entwickeln Sie die Fähigkeit des virtuellen Charakters, mittelmäßige Spielaufgaben wie Strategieoptimierung und Kampfhilfe zu übernehmen',
      text12_2: 'Meilensteine:',
      text12_3: 'Über 6 Millionen Nutzer',
      text12_4: 'Geteilte GPUs über 4000',
      text12_5: 'Partnerschaften mit über 200 Cloud-Internet-Cafés',
      desc13: '2026',
      text13_1: 'AI-Agent fortgeschrittene Spieloperationen:',
      text13_2: 'Ermöglichen Sie es dem virtuellen Charakter, proaktiv fortgeschrittene Spieloperationen durchzuführen, einschließlich globaler Strategieplanung und der Erfüllung von schwierigen Aufgaben, um die Benutzererfahrung weiter zu verbessern',
      text13_3: 'Ziel ist es, mehr als 15 Millionen Downloads für DeepLink zu erreichen, 50.000 geteilte Computer zu haben und Partnerschaften mit 800 Cloud-Internetcafés einzugehen',
    },
    cont12: {
      text: 'Auf der DBC-Kette werden 90 Milliarden ausgegeben, und auf der BSC-Kette werden 10 Milliarden ausgegeben',
      text1: 'Investition',
      text1_1: 'Seed-Runde',
      desc1_1: '2 Monate Sperrfrist, 20 Monate lineare Freigabe',
      text1_2: 'Angel-Runde',
      desc1_2: '1 Monat Sperrfrist, 10 Monate lineare Freigabe',
      text1_3: 'A-Runde',
      desc1_3: '1 Monat Sperrfrist, 10 Monate lineare Freigabe',
      text1_4: 'Öffentlicher Verkauf',
      desc1_4: '40% Freigabe vor TGE, der Rest beginnt am 30. August mit 6-monatiger linearer Freigabe',
      text2: 'Team',
      desc2: '2 Monate Sperrfrist, 20 Monate lineare Freigabe',
      text3: 'Airdrop',
      desc3: '20% Freigabe vor TGE, 8 Monate lineare Freigabe',
      text4: 'NFT-Verkäufe',
      desc4: '20% Freigabe vor TGE, 8 Monate lineare Freigabe',
      text5: 'Mining',
      text5_1: 'GPU',
      desc5_1: '16% sind für Miner, die GPU-Leistung bereitstellen, und halbieren sich alle 4 Jahre. Mining-Belohnungen beginnen nach TGE. 25% der Belohnungen werden sofort freigegeben, der Rest folgt einem linearen 150-Tage-Freigabeplan.',
      text5_2: 'Bandbreite',
      desc5_2: '4% sind für Bandbreiten-Mining, halbiert sich alle 4 Jahre. 25% der Belohnungen werden sofort freigegeben, der Rest folgt einem linearen 150-Tage-Freigabeplan.',
      text5_3: 'Wettbewerb',
      desc5_3: 'Keine Sperrfrist, 10% der Belohnungen werden sofort freigegeben, der Rest folgt einem linearen 180-Tage-Freigabeplan.',
      text6: 'Stiftung',
      desc6: '6 Monate Sperrfrist, 8 Quartale lineare Freigabe',
      text7: 'Rechtsberater',
      desc7: '1 Monat Sperrfrist, 10 Monate lineare Freigabe',
      text8: 'Ökosystem',
      desc8: '20% Freigabe vor TGE, 8 Monate lineare Freigabe',
      text9: 'Staking',
      text9_1: 'Langfristig',
      desc9_1: 'Keine Sperrfrist, 180 Tage lineare Freigabe',
      text9_2: 'Kurzfristig',
      desc9_2: 'Keine Sperrfrist, 90 Tage lineare Freigabe',
      text10: 'Gesamt',
      text11: 'Menge (Milliarden):',
      text12: 'Beschreibung:',
      text13: 'Im Umlauf (Milliarden):',
      text14: 'Nicht im Umlauf (Milliarden):',
      text15: '100 Milliarden'
    },
    cont13: {
      title: 'Team und Berater',
      intro1: 'CEO von DeepLink',
      intro2: 'Mitbegründer von DeepLink',
      intro3: 'Chief Operating Officer (COO) von DeepLink',
      intro4: 'Chief Business Officer (CBO) von DeepLink und Leiter des indischen Marktes',
      intro5: 'Berater',
      intro6: 'Berater',
      intro7: 'Berater',
      intro8: 'Mitbegründer und Chief Marketing Officer',
      user1_1: 'Serienunternehmer mit 13 Jahren Erfahrung in künstlicher Intelligenz und 7 Jahren in der Entwicklung von Blockchain- und verteilten Rechnernetzwerktechnologien.',
      user1_2: 'Abschluss im Jahr 2008 an der China Ocean University im Fachbereich Informatik.',
      user1_3: 'Im Jahr 2012 gründete er Ju Shang Intelligent und entwickelte den weltweit ersten chinesischen AI-Sprachassistenten "Smart 360".',
      user1_4: 'Innerhalb von drei Jahren überstieg die Downloadzahl der Software 17 Millionen. Im Jahr 2014 brachte er den weltweit ersten AI-Lautsprecher "Xiao Zhi Speaker" heraus.',
      user1_5: 'Im Mai 2017 gründete er DeepBrainChain und dient Hunderten von Kunden in den Bereichen AI, Cloud-Gaming und Cloud-Internetcafés.',
      user2_1: 'Seriengründer, erfahrener Experte für Cloud-Gaming. War zuvor Direktor des Shunwang Cloud Gaming Instituts, zuständig für Technologieentwicklung.',
      user2_2: 'Absolvierte 1999 ein Studium der Informatik an der Zhejiang Universität. Arbeitete von 2001 bis 2004 bei Zhejiang LanDe im Bereich Telekommunikation.',
      user2_3: 'Gründete 2009 den ersten Dual-Core-Browser Chinas, "Sunflower Browser".',
      user2_4: 'Tritt von 2010 bis 2021 dem börsennotierten Unternehmen Shunwang bei und gründete Shunwang Cloud Computer, leitete das Shunwang Institut. Er bot Lösungen für Cloud-Internetcafés an und verwaltete Cloud-Spiele, was sein Unternehmen zum ersten in China machte, das Cloud-Spiel-Lösungen anbot.',

      user3_1: 'Ausbildungshintergrund: 2007, Fachbereich Chinesisch, Universität Peking',
      user3_2: '2023: Chief Operating Officer bei DeepLink',
      user3_3: '2022-2023: CEO von DeepBrain Chain',
      user3_4: '2019-2021: Leiter des Überseemarktes bei CELLOGIN',
      user3_5: '2018: CEO von FLOW FACTORY',
      
      user4_1: 'Bildung und Zertifikate: - Bachelor of Business Administration von der Universität Mysore, 2020.',
      user4_2: '- Zertifikat in digitalem Marketing.',
      user4_3: '- Zertifikat in Suchmaschinenoptimierung (SEO).',
      user4_4: '- Blockchain-Zertifikat.',
      user4_5: 'Vertrieb und Marketing bei Technic Constructions: - Tätigkeit im Vertrieb und Marketing, innerhalb von drei Monaten 500.000 Dollar Umsatz erzielt.',
      user4_6: "Business Development Assistant bei BYJU'S: - Tätigkeit von 2020 bis 2021.",
      user4_7: '- Spielte eine Schlüsselrolle in einem Einhorn-Startup im Wert von 10 Milliarden Dollar, förderte das Wachstum, baute Partnerschaften auf und vereinfachte die Abläufe in den Bildungstechnologieprogrammen.',
      user4_8: 'Mitbegründer von Space Inn: - Mitbegründung eines unabhängigen Projekts, das sich auf die Entwicklung unternehmerischer Fähigkeiten, strategisches Denken und geschäftliches Geschick konzentriert.',
      user4_9: 'Investition/Handel am Aktienmarkt: - Fünf Jahre Erfahrung in Investitionen und Handel am Aktienmarkt, demonstriert Fachwissen im Navigieren und Nutzen von Investitionsmöglichkeiten auf den Finanzmärkten.',
      user4_10: 'Praktika in Unternehmensfirmen: - Mehrere Praktika in verschiedenen Unternehmensfirmen absolviert, wertvolle Einblicke in Unternehmenskultur, effiziente Prozesse und wirksame Geschäftsstrategien gewonnen.',
      user4_11: 'Erfahrung in der Kryptowährungsbranche: - Beteiligung an mehreren Krypto- und NFT-Projekten, wertvolle Branchenerfahrung gesammelt.',
      user4_12: '- Beitrag zum bedeutenden Real-Fi Cardano-Projekt Empowa, Förderung seiner Entwicklung und seines Wachstums. Beitrag zum Cardano-Projekt Empowa im Jahr 2022, Förderung seines Wachstums.',
      user4_13: '- Arbeitet derzeit mit DeepLink Cloud zusammen, erweitert seine Expertise im Bereich Kryptowährungen.',

      user5_1: 'Seit 2012 ist er Vizepräsident bei Hyunjin ICT, einem Unternehmen für IoT- und Kommunikationsdienste, und arbeitet dort seit 11 Jahren.',
      user5_2: 'Verkaufs- und Marktleitung mit Erfahrung in der Durchführung mehrerer Großprojekte für öffentliche Einrichtungen.',
      user5_3: 'Arbeitete 14 Jahre (1998-2012) beim größten IT-Unternehmen Südkoreas, Samsung SDS, in den Bereichen Informationsstrategieberatung, Business-Innovationsprojekte und Softwareentwicklung.',
      user5_4: 'Arbeitete 3 Jahre (2017-2019) bei der Kryptowährungsbörse Coinzest, mit Erfahrungen als Nummer eins in Südkorea und unter den Top 10 weltweit.',
      user5_5: 'Gründer von Hyunjin Venus, einem Unternehmen, das seit 5 Jahren ein Wohlfahrtseinkaufszentrum für große Unternehmen und öffentliche Institutionen in Südkorea betreibt.',
      user5_6: 'Besitzt die Qualifikationen PMP (Project Management Professional) und CISA (Certified Information Systems Auditor).',
      
      user6_1: 'Bildungshintergrund: 2003, Guanghua School of Management, Peking University',
      user6_2: '2023: CEO von MetABC',
      user6_3: '2020: CEO von HillstoneHub',
      user6_4: '2019: CEO von Hillstone Business Center',
      user6_5: '2019: COO von Hillstone Global (Blockchain)',
      user6_6: '2017: Gründer von Foundersbridge',
      user6_7: '2017: Partner bei Hillstone Partners (Private Equity)',
      user6_8: '2014: Mitbegründer von BrightA Consulting',
      user6_9: '2012: Senior Berater (China Business Experte)',

      user7_1: 'Joseph Alexander ist der Schöpfer des maßgeschneiderten Großmodells ChatDBC.com für Deep Brain Chain.',
      user7_2: 'Sein Fachgebiet ist die Geschäftsentwicklung von DeepLink und DBC in Nordamerika.',
      user7_3: 'Folgen Sie ihm auf Twitter unter {@}ChatDBC um alle Neuigkeiten zu DeepLink und DBC zu erhalten.',
      
      user8_1: 'Maryna Barysheva ist die COO von LKI Consulting, einer preisgekrönten Web3-Marketingagentur, die auf Deep Tech, DeFi, Infrastruktur und Spieleprojekte spezialisiert ist.',
      user8_2: 'Sie hat erfolgreich Blockchain-Projekte in über 23 Ländern erweitert, Marketingbudgets zwischen 1 und 10 Millionen Dollar verwaltet und das Marketing für ein 100 Millionen Dollar schweres B2B-Technologie-Startup geleitet.',
      user8_3: 'Marynas Fachgebiete sind Community-Entwicklung, Markenbekanntheit und globale GTM-Strategien.',
      user8_4: 'Sie ist eine internationale öffentliche Rednerin, tritt häufig auf Kryptokonferenzen weltweit auf und ist Mentorin für Inkubationsprogramme für KI, Technologie und Blockchain an der Universität Helsinki.',
      
      user9_1: 'Brando ist ein Veteran im Bereich der Kryptowährungen. Er begann 2016 mit der Erforschung von Kryptowährungen, was zum bekannten Bullenmarkt 2017 führte.',
      user9_2: 'Er gründete Bigger Than Race™️ im Jahr 2012 und benannte es 2017 in seinen heutigen Namen um.',
      user9_3: 'Seine Qualifikationen umfassen eine Web3/Metaverse-Zertifizierung von EveryRealm, einem Risikokapitalfonds und einem führenden Innovator und Entwickler im Metaverse, sowie Blockchain- und Bitcoin-Zertifizierungen von IBM.',
      user9_4: 'Der Krypto-Genie BRANDO MURPHY',
      user9_5: 'Seine Hauptvision und -ziel ist es, Menschen zu helfen, durch Blockchain-Technologie finanzielle Freiheit zu erlangen, und sie zu befähigen, in die Infrastruktur der neuen Ökonomie der vierten und fünften industriellen Revolutionen zu investieren und zu besitzen. Die BTR Community VC hilft globalen treuen Mitgliedern, die Technologien der Zukunft zu besitzen.',
      user9_6: 'Im Jahr 2024 wurde er Mitbegründer und Chief Marketing Officer des DeepLink Protocol, einer KI-Cloud-Gaming-Technologie, die weltweit faire Wettbewerbsbedingungen für Spieler bietet. Seine Heimatstadt ist Los Angeles, Kalifornien.',
      
      user10_1: 'Summer ist Gründer und Moderator von FireHustle, einem Medienunternehmen, das sich auf Kryptoprojekte, Investitionsstrategien und mehr konzentriert. Summer hat einen geschäftlichen Bildungshintergrund und umfangreiche Erfahrung im Rundfunk und in der Kommunikation.',
      user10_2: 'Zu seinem Medienportfolio gehören ein YouTube-Kanal mit über 44.000 Abonnenten, eine dedizierte Discord-Community mit über 3.000 Mitgliedern, eine private Mitgliedergruppe und mehr.',
      user10_3: 'In Bezug auf Investitionen hat FireHustle persönlich in über 50 Web3-Startups investiert und ist Marketing-, Fundraising- und Beratungspartner für viele Kryptoprojekte, Venture-Capital-Firmen, Startup-Plattformen, Syndikate, KOL-Gruppen usw.',
      
      user11_1: 'Manny ist ein Blockchain-Enthusiast und -Experte mit über zehn Jahren Branchenerfahrung, der seit 2013 zu führenden Unternehmen und Programmen beiträgt.',
      user11_2: 'Seine berufliche Laufbahn umfasst Einstiegspositionen bei Grayscale, das Betriebsteam von Coinbase, die Marketingteams von DYDX und Ledger sowie zuletzt eine Position bei Binance US.',
      user11_3: "Ausbildung: Er hat Kurse im Zusammenhang mit Blockchain und Kryptowährungen an führenden US-Institutionen (insbesondere der Stanford University) absolviert, einschließlich 'Umfassende Einführung in Blockchain und Kryptowährungen', 'Fortgeschrittene Blockchain-Strategien', 'CEO-Programm' und 'CMO-Programm'. Er ist spezialisiert auf Finanzen und Betriebswirtschaft.",
    },
    cont14: {
      title: 'Unsere Investmentinstitute',
      organ1: 'Die DeepBrain Chain Foundation wurde 2017 gegründet. Im Januar 2018 wurde der DBC-Token an der Huobi-Börse gelistet. Das Mainnet wurde 2021 gestartet. DeepBrain Chain ist ein hochleistungsfähiges, verteiltes Computernetzwerk, das sich auf GPUs konzentriert. Die Vision ist der Aufbau eines unendlich skalierbaren, verteilten Hochleistungs-Computernetzwerks auf Basis der Blockchain-Technologie, das zur wichtigsten Recheninfrastruktur für das AI+Metaverse-Zeitalter wird. Es bietet hauptsächlich GPU-Rechendienste in den Bereichen künstliche Intelligenz, Cloud-Gaming und Film-Rendering an. Derzeit bedient es Hunderte von Unternehmen im Bereich der künstlichen Intelligenz und des Cloud-Gamings sowie über 30.000 AI-Fachleute.',
      organ2: 'Gobi Capital ist ein professionelles Venture-Capital-Unternehmen mit Hauptsitz in Shanghai, Peking und Südostasien, das sich auf Investitionen in Technologieprojekte in der Frühphase in China konzentriert. Zu den strategischen Investoren des Gobi-Fonds gehören IBM, Sierra Ventures, McGraw-Hill und Steamboat Ventures (das Venture-Capital-Arm von Disney). Gobi hat in bekannte Startups wie Tuniu, Camera360 und Zhu Yun investiert.',
      organ3: 'Hycons ist eine GPU-Computing-Cloud-Plattform, die auf DBC-Technologie basiert und von Hyunjin ICT betrieben wird. Hycons zeichnet sich durch die verteilte Hochleistungsnetzwerktechnologie auf DBC-Basis und die latenzfreie HD-Streaming-Technologie auf DeepLink-Basis aus. Die Plattform bietet umfassende Dienstleistungen einschließlich Mitgliedschaftsverwaltung, Registrierung und Zahlung für verschiedene Diensttypen wie Unternehmen, Schulen, Forschungseinrichtungen und Franchise-Betreiber an. Derzeit betreibt Hycons mehr als 1.000 GPU-Karten und 2 Zentren in Südkorea und plant, auf 5 Zentren zu erweitern. Hycons testet auch Anwendungen im Franchise-Geschäft für Internetcafés. Mit fortschrittlicher technischer Basis, wettbewerbsfähigen Cloud-Service-Gebühren und automatisierten Dienstleistungen strebt Hycons an, die weltweit führende GPU-Computing-Plattform zu werden.',
      organ4: 'ROCK Capital ist ein professioneller Kryptowährungsfonds, der 2018 gegründet wurde und seinen Hauptsitz in Südkorea hat. ROCK Capital vereint Fachwissen in den Bereichen Finanzinvestitionen, Blockchain und Dezentralisierung und arbeitet intensiv mit verschiedenen nationalen und internationalen Unternehmen, globalen Projekten, Regierungen, Institutionen und anderen wirtschaftlichen Akteuren zusammen. Über 100 Projekte wurden erfolgreich umgesetzt, und mehrere zukünftige Finanzbereiche wurden erschlossen. ROCK ist bestrebt, hohe Renditen zu erzielen, Investitionsmöglichkeiten zu erweitern und Kapitalaktivitäten zu maximieren, um konkrete Kundenerlebnisse zu schaffen.',
      organ5: 'Dieses Venture-Capital-Unternehmen konzentriert sich auf dezentrale Blockchain-Technologie mit aufstrebenden Technologien und wird die vierte und fünfte Revolution vorantreiben. Mit umfangreichem Wissen aus verschiedenen Branchen ist es in der Lage, Chancen im gesamten Web3-Bereich und darüber hinaus zu nutzen. Sie investieren gemeinsam mit bekannten Marken wie Bloktopia, Animoca Brands und Stripe und haben starke Beziehungen zum Singularity-Netzwerk, dem Shiba-Ökosystem und verschiedenen Spiele-Studios aufgebaut. Inhaber des BTR-Army-Passes können ihre Dienstleistungen in Anspruch nehmen.'
    },
    cont15: {
      title: 'Gemeinschaftsaktivitäten',
      
    }
  },
  coverage: {
    title: 'Weltweite Benutzerverteilungskarte der DeepLink-Software',
    text: 'Gesamtzahl der DeepLink-Benutzer:'
  },
}