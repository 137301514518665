export default {
  meau: {
    nav: 'Trang chủ',
    "nav1": "Mua nút khai thác",
    "nav2": "Cuộc thi GPU Orion",
    nav3: 'Phần mềm DeepLink',
    nav3_1: 'Tải phần mềm',
    nav3_2: 'Giấy phép phần mềm',
    nav4: 'Khai thác băng thông',
    "nav5": "Cafe Internet Đám mây",
    "nav6": "Blog",
    nav7: 'Docs'
  },
  cont1: {
    text1: 'Phần mềm điều khiển từ xa chơi game chuyên nghiệp',
    text2: 'Nền tảng chơi game đám mây',
    btn: 'Tải ngay miễn phí',
    text3_1: 'Tham gia',
    text3_2: 'cộng đồng',
    text3_3: 'để nhận thưởng DeepLink Coin!'
  },
  cont2: {
    text1: 'DeepLink',
    text2: 'Dựa vào khả năng kỹ thuật độ trễ thấp cấp độ Esport, chơi game mượt mà',
    text3: 'Năng lực cốt lõi và thế mạnh kỹ thuật',
    text4: 'Dựa trên các khả năng cốt lõi của chơi game trên đám mây, trải nghiệm vượt trội hơn nhiều so với điều khiển từ xa truyền thống!',
    leaf1: {
      text1: 'Độ mượt cao, hiệu năng cao',
      text2: 'Chi phí thấp',
      text3: 'Giải pháp mã hóa dẫn đầu ngành giúp tiêu thụ tài nguyên tối thiểu với cùng lưu lượng mã ',
      text4: 'Mạng ngang hàng P2P: Hỗ trợ ipv6 với thông lượng NAT lên tới 95% mà không tốn dung lượng băng thông.'
    },
    leaf2: {
      text1: 'Tốc độ khung hình cao, độ trễ thấp',
      text2: '',
      text3: "Với độ trễ LAN <15 mili giây và khả năng hiển thị 4K 240Hz, WAN cũng được đánh giá thuộc hàng đầu thế giới."
    },
    leaf3: {
      text1: 'Độ nét cao',
      text2: '',
      text3: 'Các thuật toán thông minh làm tăng chất lượng hình ảnh ở cùng tốc độ bit và tái tạo màu sắc chân thực nhất.'
    },
    leaf4: {
      text1: 'Độ ổn định cao',
      text2: '',
      text3: 'Các chiến lược chống mất gói tin và chống tắc nghẽn được hỗ trợ bởi lượng lớn dữ liệu, cho phép nhanh chóng điều chỉnh tình trạng mất gói tin và tắc nghẽn dữ liệu.'
    },
    leaf5: {
      text1: 'Độ bảo mật cao',
      text2: '',
      text3: 'Tất cả dữ liệu được mã hóa bởi DTLS1.2 với AES256.'
    }
  },
  cont3: {
    text1: "Mượt như đang điều khiển thiết bị tại chỗ",
    text2: 'Áp dụng hàng chục kỹ thuật tối ưu hóa trong ba lĩnh vực chính, DeepLink có thể duy trì tốc độ khung hình cao dù cho có tỷ lệ mất gói tin 5% và độ trễ mạng 100 mili giây*. Đáp ứng yêu cầu trong mọi thể loại môi trường mạng.',
    li1: {
      text1: 'Công nghệ đối phó với mạng yếu',
      text2: 'Chiến lược chống mất gói tin FEC',
      text3: 'Dự báo băng thông',
      text4: 'Chiến lược mã hóa SVC'
    },
    li2: {
      text1: 'Kỹ thuật tối ưu hóa đường truyền',
      text2: 'Dự đoán cổng vân tay',
      text3: 'Hỗ trợ IPV6',
      text4: 'UPNP',
      text5: 'Định tuyến thông minh'
    },
    li3: {
      text1: 'Công nghệ xử lý hiển thị',
      text2: 'Chiến lược thích ứng tốc độ khung hình',
      text3: 'Dự đoán hình ảnh chuyển động',
      text4: 'Chiến lược xử lý biến thiên độ trễ',
      text5: 'Kết xuất hình ảnh HD nền tảng AI mã thấp',
      text6: 'Ứng dụng thực tế ảo động'
    },
    desc3: 'LAN < 5 mili giây - Mạng liên thành < 10 mili giây - Mạng liên tỉnh < 30 mili giây'
  },
  cont4: {
    text1: 'Chất lượng đồ họa siêu rõ nét',
    text2: 'Độ phân giải Ultra HD',
    text3: 'Hỗ trợ 1,07 tỷ màu',
    text4: '4:4:4 chất lượng hình ảnh không bị hư hại',
    text5: "DeepLink luôn sử dụng các chỉ số chất lượng hình ảnh khắt khe hơn để đạt được trải nghiệm hình ảnh vượt xa các công nghệ điều khiển từ xa truyền thống khác, với độ méo hình ảnh từ xa nằm dưới giới hạn nhận dạng của mắt người, hỗ trợ trọn vẹn cho nhu cầu hiển thị hình ảnh chính xác trong ngành công nghiệp sáng tạo.",
    text6: '*PSNR đề cập đến Tỷ lệ tín hiệu cực đại trên nhiễu, dưới 20dB độ méo hình ảnh cao, 20-30dB độ méo hình ảnh ở mức trung bình, 30-40dB độ méo hình ảnh thấp, trên 40dB độ méo hình ảnh cực kỳ thấp và không thể phát hiện bằng mắt thường.'
  },
  cont5: {
    text1: 'Hỗ trợ ngoại vi rộng rãi',
    text2: 'DeepLink cung cấp hỗ trợ từ xa cho các công cụ chuyên dụng trong ngành công nghiệp sáng tạo như bảng kỹ thuật số và cần điều khiển, đồng thời tối ưu hóa việc sử dụng phần mềm sáng tạo như PS, Zbrush và Unity để đảm bảo trải nghiệm nhất quán các công cụ sản xuất sáng tạo bất kể khi dùng từ xa hay tại chỗ.'
  },
  cont6: {
    text1: 'Bảo mật đa tầng - Tiếp cận đơn giản',
    block1: {
      title1: 'Tài khoản an toàn',
      desc1: 'Cơ chế bảo vệ tài khoản nhiều lớp đảm bảo yên tâm',
      title2: 'Kết nối an toàn',
      desc2: 'Giao thức bảo mật DTLS1.2, chỉ truy cập khi được ủy quyền',
      title3: 'Dữ liệu an toàn',
      desc3: 'Mã hóa AES 256-bit, an toàn trong suốt quá trình truyền dữ liệu',
      title4: 'Thiết bị an toàn',
      desc4: 'Chỉ người tương ứng mới nhìn thấy thiết bị tương ứng theo cài đặt của bạn'
    },
    block2: {
      title1: 'Bảo mật đa tầng',
      desc1: 'Bảo mật mọi kết nối',
      title2: 'Dễ sử dụng',
      desc2: 'Dễ bắt đầu'
    },
    block3: {
      title1: 'Tải xuống nhẹ',
      desc1: "Phần mềm DeepLink chỉ nặng khoảng 10M đối với bản người dùng cuối cùng",
      title2: 'Dễ học',
      desc2: 'Cài đặt theo hướng dẫn',
      title3: 'Dễ sử dụng',
      desc3: 'Điều khiển từ xa bất kỳ thiết bị thông qua chuột'
    }
  },
  cont7: {
    text1: 'Tâm huyết trong việc cung cấp các giải pháp điều khiển từ xa',
    block1: {
      word1: 'Ngành giáo dục',
      word2: 'Ngành công nghiệp trò chơi',
      word3: 'Ngành sáng tạo & thiết kế',
      word4: 'Ngành công nghệ thông tin',
      word5: 'Nhiều nhà kinh doanh đang được khai sinh',
      word6: 'Nhiều đối tác trên khắp thế giới',
      word7: 'Dịch vụ Vận hành và Bảo trì từ xa IT O&M',
      word8: 'Người dùng cá nhân và game thủ'
    },
    block2: {
      text1: 'Hỗ trợ các tình huống điều khiển từ xa trong nhiều ngành khác nhau',
      text2: 'Dựa trên nhu cầu thực tế của doanh nghiệp trong các ngành khác nhau, chúng tôi giúp họ đạt được mục tiêu kinh doanh theo cách tiết kiệm năng lượng.',
      text3: 'Cung cấp các chính sách cá nhân hóa và mã hóa, linh hoạt, bảo mật',
      text4: "Nhân viên công ty có thể dựa trên phân quyền truy cập tài nguyên của công ty mọi lúc mọi nơi."
    },
    block3: {
      text1: 'Đáp ứng nhu cầu đa dạng của người dùng',
      text2: 'Cho dù bạn chọn sử dụng cho nhân viên nội bộ,',
      text3: 'hay cho khách hàng, đối tác bên ngoài hoặc thậm chí dùng cho cá nhân.',
      text4: 'Ứng dụng điều khiển từ xa của chúng tôi có thể phục vụ bạn trên các thiết bị và nền tảng chính,',
      text5: 'đưa ra một phương thức thay thế có thể giải quyết vấn đề một cách đơn giản và thông minh.'
    }
  },
  cont9: {
    block1: {
      text1: 'Bảo mật đa tầng',
      text2: 'Bảo vệ an toàn mọi kết nối',
    },
    block2: { 
      title1: 'Quán Internet chia sẻ',
      text1_1: 'Mã hóa AES 256-bit',
      text1_2: 'Bảo mật suốt quá trình truyền tải',
      title2: 'Bảo mật kết nối',
      text2_1: 'Giao thức bảo mật DTLS1.2',
      text2_2: 'Truy cập theo cấp quyền',
      title3: 'Bảo mật thiết bị',
      text3_1: 'Cài đặt cho phép đối tượng tương ứng chỉ nhìn thấy thiết bị tương ứng',
      title4: 'Bảo mật tài khoản',
      text4_1: 'Cơ chế đa tầng dựa trên công nghệ blockchain, bảo vệ tài khoản và thông tin thiết bị, mã hóa truyền tải P2P, không thông qua máy chủ tập trung',
    },
    block3: {
      text1: 'Quán Internet chia sẻ',
      text2: 'Thông qua DeepLink, chủ quán Internet có thể cho thuê máy game để kiếm thu nhập, và người chơi có thể ngồi ở nhà thuê chơi game từ máy của quán Internet trong phạm vi 50 km',
    },
  },
  cont10: {
    title: 'DeepLink cung cấp giải pháp quán Internet Cafe trên nền tảng đám mây',
    desc: 'Nhờ giảm chi phí cùng khả năng mở rộng không giới hạn, tối đa hóa tỷ suất lợi nhuận cho các quán Internet Cafe',
    block1: {
      title1: 'Bốn vấn đề lớn của các quán Internet hiện tại',
      text1: 'Hóa đơn tiền điện lại tăng?',
      text1_1: 'Gánh nặng tiền điện',
      text1_2: 'Giá điện tăng theo quy định Nhà nước.',
      text2: 'Quán có thể đầu tư bao nhiêu RTX-40XX?',
      text2_1: 'Gánh nặng nâng cấp GPU/CPU',
      text2_2: 'Mỗi khi ra mắt GPU mới là kèm theo gánh nặng mua nâng cấp GPU đắt tiền.',
      text3: 'Làm cách nào để người dùng thay đổi nhận thức về chi tiêu?',
      text3_1: 'Nhận thức chi tiêu',
      text3_2: 'Cần một lý do để thay đổi nhận thức cố hữu về giới hạn chi tiêu dưới 1.000 won',
      text4: 'Làm cách nào đối phó với tác động của các yếu tố môi trường bên ngoài?',
      text4_1: 'Tỷ lệ sử dụng PC thấp tại các quán Internet',
      text4_2: 'Do đại dịch, thời tiết, v.v., ít người đến quán Internet hơn; cần giải pháp xử lý các PC nhàn rỗi.',
    },
    block2: {
      title1: 'Chuỗi quán Internet Cafe đám mây',
      desc1: 'Giá trị của Internet Cafe đám mây： Giảm chi phí ban đầu và chi phí bảo trì, không phát sinh chi phí nâng cấp, mang lại lợi ích kinh tế tốt nhất cho chủ quán Internet',
      text1_1: 'Chi phí khởi nghiệp giảm tới 61%',
      text1_2: 'Giảm chi phí khởi nghiệp, giảm gánh nặng chi phí mua PC',
      text2_1: 'Độ trễ cực thấp',
      text2_2: 'Cung cấp sức tính hiệu suất cao, cải thiện tốc độ phản hồi trò chơi',
      text3_1: 'Phí bảo trì “0 đồng”',
      text3_2: 'Không có chi phí quản lý máy tính bàn',
      text4_1: 'Tăng doanh thu',
      text4_2: 'Tính phí theo cấu hình GPU. Tất cả ghế chơi đều là "phòng game cao cấp"',
      text5_1: 'Tiết kiệm tiền điện',
      text5_2: 'Tiết kiệm năng lượng và giảm thải carbon',
      text4: 'Làm cách nào đối phó với tác động của các yếu tố môi trường bên ngoài?',
      text6_1: 'Phí nâng cấp “0 đồng”',
      text6_2: 'CPU/GPU được nâng cấp ở trung tâm đám mây',
    }
  },
  footer: {
    link: 'Học nhiều hơn về',
    community: 'Hãy theo dõi cộng đồng của chúng tôi',
    tip: 'Cộng đồng của chúng tôi trên: Twitter, telegram, reddit, medium, discord, tiktok, YouTube',
    text: 'DeepLink Protocol là một giao thức chơi game đám mây AI phi tập trung kết hợp công nghệ AI, GPU và blockchain để cung cấp công nghệ render game với độ trễ cực thấp. Nó cung cấp các giải pháp chơi game đám mây hiệu quả về chi phí cho các ngành công nghiệp như trò chơi AAA, quán cà phê internet đám mây và khách sạn thể thao điện tử đám mây',
    meau1: 'Giới thiệu',
    meau2: 'Sản phẩm',
    meau3: 'Blockchain',
    meau4: 'Sự kiện Airdrop quan trọng',
    btn5: 'Windows SDK',
    meau3_text1: 'Trình duyệt DLC chuỗi BSC',
    meau3_text2: 'Trình duyệt DLC chuỗi DBC',
    meau3_text3: 'Ví chính DBC',
    meau4_text1: 'Airdrop DLC 300,000U của Bybit',
    meau4_text2: 'Airdrop DLC 360,000U của Okx',
    meau4_text3: 'Airdrop DLC 300,000U của Binance',
  },
  reward: {
    title1: 'DeepLink node',
    desc1: 'Có quyền truy cập vào các phần thưởng, ví dụ: DeepLink Token, Crown NFT, và quyền hội đồng quản trị.',
    title2: 'Nhận Genesis Node NFT',
    text1: "Càng nhiều người dùng trong mạng lưới DeepLink thì càng cần nhiều phép tính và lưu lượng truy cập. Các Genesis Node cung cấp cơ sở hạ tầng cho mạng lưới DeepLink, nhờ đó phi tập trung hóa và giúp DeepLink phát triển nhanh hơn.",
    text2: 'Bằng cách mua một Genesis Node NFT, bạn có cơ hội nhận phần thưởng cho đóng góp của mình vào mạng lưới.',
    text3: 'Đó có thể bao gồm DeepLink Token, Crown NFT, và quyền hội đồng quản trị.',
    text4_1: 'Bạn có câu hỏi? Hãy tương tác với chúng tôi trên',
    text4_2: 'và',
    text4_3: '',
    btntext: 'Mua ngay',
    text5: 'Cứ 1000 Genesis Node NFT được bán, giá sẽ tăng thêm 1000USD',
    text6_1: 'Số lượng node',
    text6_2: 'mỗi NFT hiện hỗ trợ',
    text7: 'Tổng số Genesis Node NFT',
    text8: 'Phát hành vĩnh viễn',
    text9: 'Giá ban đầu',
    text10_1: 'Số lượng node tối đa',
    text10_2: 'Được hỗ trợ trên mỗi NFT',
    text11: 'Giá tối đa',
    text12: 'Genesis node là gì?',
    text13: 'Genesis node đảm nhận nhiệm vụ cốt lõi trong việc tính toán và chuyển tiếp lưu lượng truy cập của mạng lưới DeepLink, đồng thời DeepLink cho phép nhiều người tham gia hơn để đạt được doanh thu thông qua mô hình khai thác.',
    text14: 'Tổng cộng có bao nhiêu Token đã được đưa vào các Genesis node để thưởng?',
    text15: '30% tổng số Token, tức là 30 tỷ DeepLink Token',
    text16: 'Genesis Node NFT là gì?',
    text17: 'Chỉ những người sở hữu Genesis Node NFT mới được phép tham gia khai thác.',
    text18: 'Doanh thu từ việc bán Genesis Node NFT được phân phối như thế nào?',
    text19: '50% số tiền thu được sẽ được đầu tư vào pool phần thưởng thanh khoản phi tập trung (ví dụ: DeepLink Token/USDC cho Uniswap, Sushiswap) và 50% số tiền thu được sẽ được đưa về công ty điều hành DeepLink.',
    text20: 'Cơ chế khai thác cụ thể là gì?',
    text21: 'Tổng phần thưởng là 5 tỷ một năm, cứ mỗi 3 năm thì giảm một nửa, 10% doanh thu DeepLink Crown NFT được dùng để mua lại DeepLink Token và thêm vào tổng phần thưởng trước mỗi đợt giảm một nửa, là một phần của 50% phần thưởng Token, được thưởng đến tất cả node trong 3 năm. Ví dụ: sau 3 năm, 10% thu nhập của DeepLink Crown NFT có thể mua lại 12 tỷ DeepLink Token, thì tổng phần thưởng còn lại là: 12 tỷ + 15 tỷ, tổng cộng là 27 tỷ, và tổng phần thưởng hàng năm từ đợt 3-năm lần thứ hai là 27 tỷ * 50% * 1/3 = 4,5 tỷ.',
    text22: 'Các yêu cầu đối với máy Genesis node là gì?',
    text23: "Mỗi máy phải đã nằm trong mạng lưới DeepBrain Chain. DeepBrain Chain là mạng máy tính hiệu suất cao phi tập trung, và các máy trong DeepBrain Chain đã cam kết DBC, nhờ vậy đảm bảo tính ổn định của máy. Để biết thêm thông tin về DeepBrain Chain:",
    text24: 'Những lợi ích khác của việc nắm giữ Genesis Node NFT là gì?',
    details: {
      text1: "1. Việc nắm giữ một số lượng Genesis Node NFT nhất định mang lại cho bạn quyền đại lý phổ thông để bán DeepLink NFT tại một quốc gia (cần 1 NFT cho mỗi 3 triệu người tùy theo tỷ lệ dân số của quốc gia đó);",
      text2: '2. Chương trình airdrop DeepLink Coin;',
      text3: '3. Chương trình airdrop token dự án game DeepLink ươm mầm;',
      text4: '4. Quyền bầu chọn game nào sẽ được phát hành trực tuyến;',
      text5: '5. Giảm giá mua Game NFT;',
      text6: '6. Giảm giá mua quyền chơi và giờ chơi game trên đám mây;',
      text7: '7. Các quyền khác để tham gia hội đồng quản trị.'
    },
    question: {
      text1_1: 'Genesis node là gì?',
      text1_2: 'Genesis node đảm nhận nhiệm vụ cốt lõi trong việc tính toán và chuyển tiếp lưu lượng truy cập của mạng lưới DeepLink, đồng thời DeepLink cho phép nhiều người tham gia hơn để đạt được doanh thu thông qua mô hình khai thác.',
      text2_1: 'Tổng cộng có bao nhiêu Token đã được đưa vào các Genesis node để thưởng?',
      text2_2: '10% tổng số Token, tức là 10 tỷ DeepLink Token',
      text3_1: 'Genesis Node NFT là gì?',
      text3_2: 'Chỉ những người sở hữu Genesis Node NFT mới được phép tham gia khai thác.',
      text4_1: 'Doanh thu từ việc bán Genesis Node NFT được phân phối như thế nào?',
      text4_2: '50% số tiền thu được sẽ được đầu tư vào pool phần thưởng thanh khoản phi tập trung (ví dụ: DeepLink Token/USDC cho Uniswap, Sushiswap) và 50% số tiền thu được sẽ được đưa về công ty điều hành DeepLink.',
      text5_1: 'Cơ chế khai thác cụ thể là gì?',
      text5_2: 'Tổng phần thưởng là 5 tỷ một năm, cứ mỗi 3 năm thì giảm một nửa, 10% doanh thu DeepLink Crown NFT được dùng để mua lại DeepLink Token và thêm vào tổng phần thưởng trước mỗi đợt giảm một nửa, là một phần của 50% phần thưởng Token, được thưởng đến tất cả node trong 3 năm. Ví dụ: sau 3 năm, 10% thu nhập của DeepLink Crown NFT có thể mua lại 12 tỷ DeepLink Token, thì tổng phần thưởng còn lại là: 12 tỷ + 15 tỷ, tổng cộng là 27 tỷ, và tổng phần thưởng hàng năm từ đợt 3-năm lần thứ hai là 27 tỷ * 50% * 1/3 = 4,5 tỷ.',
      text6_1: 'Các yêu cầu đối với máy Genesis node là gì?',
      text6_2: "Mỗi máy phải đã nằm trong mạng lưới DeepBrain Chain. DeepBrain Chain là mạng máy tính hiệu suất cao phi tập trung, và các máy trong DeepBrain Chain đã cam kết DBC, nhờ vậy đảm bảo tính ổn định của máy. Để biết thêm thông tin về DeepBrain Chain:",
      text7_1: 'Những lợi ích khác của việc nắm giữ Genesis Node NFT là gì?',
      text7_2: `1. Việc nắm giữ một số lượng Genesis Node NFT nhất định mang lại cho bạn quyền đại lý phổ thông để bán DeepLink NFT tại một quốc gia (cần 1 NFT cho mỗi 3 triệu người tùy theo tỷ lệ dân số của quốc gia đó);2. Chương trình airdrop DeepLink Coin;3. Chương trình airdrop token dự án game DeepLink ươm mầm;
      4. Quyền bầu chọn game nào sẽ được phát hành trực tuyến;5. Giảm giá mua Game NFT;6. Giảm giá mua quyền chơi và giờ chơi game trên đám mây;7. Các quyền khác để tham gia hội đồng quản trị.`,
    }
  },
  nft: {
    cont: {
      title1: 'Crown NFT',
      desc1: 'Với NFT này, bạn có 1 năm quyền truy cập vào tất cả tính năng dành cho doanh nghiệp của phần mềm DeepLink.',
      desc2: 'NFT không hoạt động sau khi mua, khi cần sử dụng, có thể kích hoạt NFT này trong phần mềm DeepLink. Khi NFT được kích hoạt',
      desc3: 'bắt đầu đếm ngược thời gian sử dụng 1 năm. 10% doanh thu NFT của phần mềm DeepLink được đầu tư vào phần thưởng node,',
      // desc4: '40% số tiền thu được được đầu tư vào pool phần thưởng thanh khoản và 50% là tiền thu được của công ty điều hành DeepLink.'
      desc4: '40% số tiền thu được sẽ bị tiêu hủy để tăng giá trị của DLC Token'
    },
    enabl: {
      title1: 'Quyền NFT',
      // desc1: '1 năm truy cập vào tất cả tính năng dành cho doanh nghiệp của phần mềm DeepLink',
      desc1: 'Có quyền truy cập vào các tính năng nâng cao của phần mềm DeepLink',
      desc2: 'Chương trình airdrop DeepLink Coin',
      desc3: 'Chương trình airdrop token dự án game DeepLink ươm mầm',
      desc4: 'Quyền bầu chọn game nào sẽ được phát hành trực tuyến',
      desc5: 'Giảm giá mua Game NFT',
      desc6: 'Giảm giá mua quyền chơi và giờ chơi game trên đám mây',
      desc7: 'Các quyền khác để tham gia hội đồng quản trị'

    },
    edition: {
      title1: 'Phiên bản chuyên gia',
      title2: 'Phiên bản đội',
      tip: 'Crown NFT',
      btn: 'Mua ngay',
      title3: 'Chuyên gia VS Phiên bản đội',
      desc: 'Truy cập các tính năng cụ thể',
      list: {
        li1_1: 'Nội dung',
        li1_2: 'Phiên bản cơ bản',
        li1_3: 'Phiên bản chuyên gia',
        li1_4: 'Phiên bản đội',
        li2_1: 'Chất lượng hình ảnh tốt nhất',
        li3_1: 'Chuyển tiếp phòng máy chủ server',
        li3_2: 'Số lượng nhỏ',
        li3_3: 'Số lượng lớn',
        li4_1: 'Chuyển tiếp băng thông',
        li4_2: 'Tối đa 4M',
        li4_3: 'Tối đa 50M',
        li4_4: 'Không giới hạn',
        li5_1: 'Độ trễ đầu cuối',
        li5_2: 'Tối thiểu <40ms',
        li5_3: 'Tối thiểu <20ms',
        li6_1:'Số lượng thiết bị có thể khởi tạo điều khiển từ xa cùng một lúc',
        li7_1:'Số lượng thiết bị',
        li8_1:'Desktop từ xa',
        li9_1:'Camera từ xa',
        li10_1:'SSH từ xa',
        li11_1:'Màn hình cá nhân',
        li12_1:'Màn hình ảo',
        li13_1:'Bàn phím chơi game',
        li14_1:'Đa luồng Multi-streaming',
        li15_1:'444 Màu thật',
        li16_1:'Ghi lại ảnh chụp màn hình',
        li17_1:'Từ Màn hình kép đến Màn hình kép',
        li18_1:'xử lý',
        li19_1:'Quản lý Nhóm người dùng/thiết bị',
        li20_1:'SSO Đăng nhập một lần',
        li21_1:'Ủy quyền quản lý thống nhất',
        li22_1:'Triển khai hàng loạt',
        li23_1:'Kiểm tra hành vi của nhân viên',
        li24_1:'Đóng dấu mờ Watermark',
        li25_1:'Giá',
        li26_1: 'Phiên máy tính để bàn một máy',
        li27_1: 'chuyển tập tin',

        new_text1: 'Nếu cùng một địa chỉ ví được đăng nhập trên nhiều thiết bị cùng một lúc, chỉ có một trong các thiết bị có thể khởi động điều khiển từ xa.',
        new_text2: 'Một máy chỉ có thể mở hai cửa sổ điều khiển từ xa',
      }
    }
  },
  internetCafe: {
    title1: 'DeepLink cung cấp giải pháp quán Internet Cafe đám mây',
    block1: {
      text1: 'Giảm hơn 60% chi phí đầu tư phần cứng của các chủ quán Internet',
      text2: 'Rút ngắn hơn 40% thời gian hoàn vốn đầu tư',
      text3: 'Xem video',
      text4: 'Xem tập tin',
    },
    title2: 'Giá NFT cho Internet Cafe đám mây',
    block2: {
      desc: '40% số tiền thu được sẽ được đầu tư vào mining pool DLC Coin trên sàn DEX để thưởng cho các nhà đầu tư DLC',
      text1_1: 'Hỗ trợ 50 máy',
      text1_2: 'Một năm 3600 USDT',
      text2_1: 'Hỗ trợ 100 máy',
      text2_2: 'Một năm 7200 USDT',
      text3_1: 'Hỗ trợ 150 máy',
      text3_2: 'Một năm 10800 USDT',
      text4_1: 'Hỗ trợ 200 máy',
      text4_2: 'Một năm 14400 USDT',
    },
    title3: 'DeepLink tuyển Đại lý toàn cầu ngành Internet Cafe đám mây',
    block3: {
      text1_1: 'Quyền lợi',
      text1_2: 'Được mua NFT Internet Cafe đám mây với giá thấp hơn giá thị trường',
      text2_1: 'Trách nhiệm',
      text2_2: 'Tìm đối tác kinh doanh Internet Cafe đám mây tại khu vực đại lý, đối tác có khả năng cung cấp dịch vụ Internet Cafe đám mây cho các quán Internet',
      text3_1: 'Yêu cầu',
      text3_2: 'Có nguồn lực liên quan ngành Internet Cafe tại khu vực đại lý, sở hữu số lượng DLC Coin tương đương với dân số khu vực đại lý',
    },
    title4: 'Q&A về Internet Cafe đám mây ',
    block4: {
      text1_1: 'Internet Cafe đám mây là gì？',
      text1_2: 'Internet Cafe đám mây là quán Internet chỉ cần trang bị màn hình và mini PC，không cần trang bị GPU cấu hình cao. Nhờ kết nối từ xa với máy chủ GPU qua mạng, người chơi tại quán Internet có thể tùy ý lựa chọn sử dụng các loại máy chủ GPU khác nhau. Quán Internet không cần quản lý tài nguyên game. Quán Internet chỉ cần thu phí tính theo thời gian sử dụng của người chơi.',
      text2_1: 'Làm thế nào để đăng ký làm đại lý？',
      text2_2: 'Vui lòng liên hệ chúng tôi tại nhóm telegram：',
      text3_1: 'Đại lý được hưởng những quyền lợi gì？',
      text3_2: 'Đại lý độc quyền trong khu vực đại lý, phát triển nền tảng đám mây để phục vụ các quán Internet và hưởng giá chênh lệch từ mỗi NFT.',
      text4_1: 'Đại lý có cần ký thỏa thuận chính thức với DeepLink không?',
      text4_2: 'Đại lý cần ký thỏa thuận chính thức với DeepLink.',
      text5_1: 'Thu nhập NFT Internet Cafe đám mây tác động như thế nào đến giá DLC?',
      text5_2: '40% thu nhập NFT Internet Cafe đám mây sẽ được để vào sàn phi tập trung, ví dụ cặp DLC-USDT trên Uniswap, dùng để mua DLC, DLC đã mua sẽ bị tiêu hủy.',
      text6_1: 'Làm thế nào đảm bảo tính minh bạch của việc dùng thu nhập NFT Internet Cafe đám mây để mua DLC？',
      text6_2: 'Số lượng NFT Internet Cafe đám mây đã phát hành có thể được truy vấn on-chain, thông qua số lượng đã phát hành có thể tính được thu nhập NFT.',
    },
    title5: 'Đối tác Internet Cafe đám mây'
  },
  gameFi: {
    title1: 'Nền tảng GameFi đám mây',
    block1: {
      text1: 'Nền tảng GameFi đám mây',
      text1_1: 'Ai cũng có thể chơi GameFi thông qua DeepLink mà không cần tải xuống',
      text1_2: 'Không gặp tình trạng hạn chế tải game ở một số quốc gia. Không cần tải từ Apple Appstore',
      text1_3: 'Giúp các nhà sản xuất GameFi thu hút thêm nhiều người chơi',
      text2: 'Phương thức hợp tác với nhà sản xuất game',
      text2_1: 'Hợp tác quảng cáo',
      text2_2: 'Hợp tác lưu lượng người dùng',
      text2_3: 'Chia sẻ lợi nhuận từ game',
      text2_4: 'Hợp tác NFT',
    },
    title2: 'Q&A về GameFi',
    block2: {
      text1_1: 'Nền tảng GameFi đám mây là gì？',
      text1_2: 'DeepLink sẽ chọn đối tác là các nhà làm game chất lượng cao, đưa game của họ lên nền tảng đám mây DeepLink. Người dùng thông qua DeepLink trực tiếp chơi GameFi mà không cần tải xuống',
      text2_1: 'Nền tảng GameFi đám mây có hỗ trợ game mobile không？',
      text2_2: 'Chúng tôi sẽ hỗ trợ PC để chơi game mobile, và có thể mở nhiều cửa sổ game cùng một lúc',
      text3_1: 'Lợi ích hợp tác giữa nhà sản xuất GameFi và DeepLink là gì？',
      text3_2: 'DeepLink sẽ giúp các nhà làm game quảng bá thương hiệu và thu hút nhiều người chơi hơn',
      text4_1: 'Nhà sản xuất GameFi có cần ký thỏa thuận chính thức với DeepLink không?',
      text4_2: 'Không cần. DeepLink sẽ sàng lọc đối tác là các nhà làm game chất lượng cao. Các game được sàng lọc sẽ tự động được đưa lên nền tảng DeepLink',
      text5_1: 'Làm thế nào để đăng ký hợp tác với DeepLink？',
      text5_2: 'Vui lòng gửi email đăng ký hợp tác đến：',
    }
  },
  cloudInternet: {
    title: 'Làm thế nào để trở thành đối tác giải pháp quán trò chơi mạng trên đám mây',
    text: 'Hiện nay, các quốc gia có số lượng quán trò chơi mạng chính trên toàn thế giới bao gồm Hàn Quốc (6000 quán), Trung Quốc (40,000 quán), Brazil (10,000 quán), Indonesia (20,000 quán), Philippines (20,000 quán), Việt Nam (15,000 quán), Thổ Nhĩ Kỳ (5000 quán), cùng với Ấn Độ, Mexico, Trung Đông, Nam Mỹ, Châu Phi, châu Âu và Mỹ và nhiều quốc gia khác. Tổng số lượng quán trò chơi mạng trên toàn thế giới lên đến hơn 150,000 quán, nhưng ngành công nghiệp này đã tồn tại trong 20 năm và đã trở thành một ngành công nghiệp truyền thống. Chu kỳ thu hồi vốn đã tăng lên đến 3-4 năm, và đối mặt với nhiều rủi ro, như đại dịch COVID-19 đã khiến nhiều quán trò chơi mạng phải đóng cửa. Do đó, ngành công nghiệp quán trò chơi mạng cần một giải pháp mới toanh, có thể giảm thiểu thời gian thu hồi vốn, giảm thiểu rủi ro và nâng cao hiệu suất vận hành quán trò chơi mạng. Và từ đó, giải pháp quán trò chơi mạng trên đám mây đã ra đời.',
    cont2: {
      text1: 'là gì',
      text2: 'Giải pháp quán trò chơi mạng trên đám mây?',
      text3: 'Giải pháp quán trò chơi mạng trên đám mây là một loại hình dịch vụ quán trò chơi mới với công nghệ kết xuất siêu thấp độ trễ. Trong quán trò chơi mạng trên đám mây, trò chơi và ứng dụng không được cài đặt trực tiếp trên máy tính cục bộ mà được lưu trữ trên các máy chủ GPU trên đám mây. Người dùng truy cập và chơi trò chơi thông qua dịch vụ đám mây mà không cần phải sử dụng phần cứng cục bộ có hiệu suất cao.',
      block1: {
        text1: 'Giảm Yêu Cầu Phần Cứng Cục Bộ',
        text2: 'Do trò chơi và ứng dụng chạy trên các máy chủ GPU trên đám mây, máy tính cục bộ không cần phải có GPU hiệu suất cao hoặc dung lượng lưu trữ lớn. Điều này giảm thiểu chi phí đầu tư và bảo trì phần cứng của quán trò chơi.'
      },
      block2: {
        text1: 'Truy Cập Ngay Lập Tức Đến Các Trò Chơi Mới Nhất',
        text2: 'Người dùng có thể truy cập các trò chơi mới nhất ngay lập tức mà không cần phải chờ đợi để tải xuống và cài đặt. Tất cả các cập nhật và bảo trì trò chơi đều được thực hiện trên đám mây, mang lại trải nghiệm thuận tiện và hiệu quả cho người dùng.'
      },
      block3: {
        text1: 'Trải Nghiệm Chơi Game Hiệu Suất Cao',
        text2: 'Màn hình cục bộ chỉ cần được trang bị mini PC (khoảng 100 đô la), và quán trò chơi mạng trên đám mây vẫn có thể cung cấp trải nghiệm chơi game hiệu suất cao. Điều này bởi vì tính toán trò chơi được thực hiện trên các máy chủ GPU hiệu suất cao từ xa.'
      },
      block4: {
        text1: 'Tiết Kiệm Không Gian và Năng Lượng',
        text2: 'Máy tính cục bộ có cấu hình thấp chiếm ít không gian hơn và tiêu thụ ít năng lượng hơn, giúp giảm chi phí vận hành của quán trò chơi.'
      },
      block5: {
        text1: 'Quản Lý và Bảo Trì Dễ Dàng',
        text2: 'Các quản lý quán trò chơi có thể dễ dàng quản lý phần mềm và trò chơi vì tất cả nội dung được quản lý tập trung trên đám mây. Điều này giảm thiểu công việc bảo trì cục bộ và các vấn đề kỹ thuật tiềm ẩn.'
      }
    },
    cont3: {
      text1: 'Glowsteam Cloud Cybercafe ở Hàn Quốc',
      text2: 'Môi Trường Hoạt Động Thực Tế của Quán Trò Chơi Mạng Trên Đám Mây'
    },
    cont4: {
      text1: 'Giải Pháp Quán Trò Chơi Mạng Trên Đám Mây Có Thể Giúp Các Nhà Đầu Tư Quán Trò Chơi Mạng',
      text2: 'Tăng Lợi Nhuận Ròng lên 3 Lần và Rút Ngắn Thời Gian Hồi Vốn lên 40%',
      text3: 'Nhấn để Tải Dữ Liệu So Sánh'
    },
    cont5: {
      text1: 'Lợi nhuận của nhà cung cấp giải pháp quán trò chơi mạng trên đám mây là như thế nào?',
      text2: 'Nếu lợi nhuận được tính là 12% của thu nhập mỗi máy trò chơi mạng trên đám mây, sau đó dịch vụ một tổng số 1000 quán trò chơi mạng với 100.000 máy, mỗi máy được sử dụng trung bình 10 giờ mỗi ngày, và với một chi phí trung bình là 0.8 đô la mỹ cho mỗi máy mỗi giờ, 12% tương đương khoảng 0.1 đô la.',
      text3: 'Lợi nhuận hàng năm của một nhà cung cấp giải pháp quán trò chơi mạng trên đám mây là',
      text4: '100.000 * 10 * 0.1 * 365 = 36.5 triệu đô la',
      text5: 'Làm thế nào để trở thành một nhà cung cấp giải pháp quán trò chơi mạng trên đám mây',
      text6: 'Dựa trên công nghệ DeepBrainChain và DeepLink, HYCONS của Hàn Quốc đã phát triển một giải pháp quán trò chơi mạng trên đám mây hoàn chỉnh. DeepBrainChain cung cấp sức mạnh tính toán GPU, DeepLink cung cấp công nghệ kết xuất với độ trễ siêu thấp, và HYCONS cung cấp toàn bộ giải pháp. Tương tự, nếu chúng ta so sánh nó với một chiếc ô tô, DeepBrainChain cung cấp động cơ, DeepLink cung cấp hệ thống lái tự động, và HYCONS cung cấp giải pháp ô tô hoàn chỉnh. Các nhà cung cấp giải pháp địa phương tại các quốc gia khác nhau chỉ cần tích hợp hệ thống thanh toán địa phương của họ vào HYCONS để cung cấp dịch vụ cho các quán trò chơi mạng địa phương, và các nhà cung cấp giải pháp có thể thiết lập thương hiệu của riêng họ. HYCONS chỉ hoạt động duy nhất như một nhà cung cấp dịch vụ công nghệ mà không tiết lộ thương hiệu của mình.',
      text7: 'Để biết thêm thông tin, vui lòng liên hệ:',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: 'Tải về tài liệu liên quan đến quán trò chơi mạng trên đám mây',
      text1: 'Giới thiệu về HYCONS',
      text2: 'Phân tích lợi nhuận từ giải pháp quán trò chơi mạng trên đám mây',
      text3: 'Sơ đồ kiến trúc Trung tâm Dữ liệu Quán trò chơi mạng trên đám mây',
      text4: 'Hướng dẫn Kiểm tra và Cài đặt Giải pháp quán trò chơi mạng trên đám mây',
      text5: 'Giới thiệu giao thức DeepLink'
    }
  },
  reward2: {
    desc: {
      title1: 'Nút DeepLink',
      text1: 'Chỉ những người sở hữu nút mới có thể tham gia khai thác lưu lượng CPU và khai thác máy GPU cá nhân. Tổng phần thưởng là 12% DLC Tokens, trong đó 4% dành cho các nút lưu lượng CPU và 8% dành cho các nút GPU cá nhân. Tổng số lượng token là 12 tỷ. Sản lượng của mỗi nút liên quan đến số lượng nút thực tế đã bán ra. Nếu tất cả được bán hết, mỗi nút sẽ sản xuất 100,000 DLC. Nếu chỉ có 10% nút được bán ra, mỗi nút sẽ sản xuất 1 triệu DLC.',
      title2: 'Tổng số nút: 120,000 không phát hành thêm',
      text2: 'Mỗi nút nhận được airdrop bổ sung',
      text3: 'Số lượng DLC khai thác tối thiểu của mỗi nút',
      text4: 'Giá ban đầu',
      text5: 'Giá cao nhất'
    },
    btn_text: 'Mua Node',
    table: {
      tier: 'Cấp độ',
      license: 'Giá giấy phép nút (USDT)',
      total_num: 'Tổng số giấy phép',
      total_usdt: 'Tổng USDT',
      buy: 'Mua'
    },
    intro: {
      title: 'Giới thiệu khai thác nút',
      text1_1: 'Càng có nhiều người dùng mạng DeepLink, nhu cầu về lưu lượng mạng và khả năng tính toán càng lớn, điều này dẫn đến nhu cầu nhiều hơn về các máy lưu lượng CPU và máy tính GPU để cung cấp cơ sở hạ tầng cho mạng DeepLink, đạt được phi tập trung hóa và giúp DeepLink phát triển nhanh hơn. Bằng cách mua quyền hạn nút, bạn có cơ hội tham gia khai thác và nhận phần thưởng. Có thắc mắc nào không? Hãy đến',
      text1_2: 'và',
      text1_3: 'trò chuyện với chúng tôi ở trên.',
      text2: 'Mỗi nút được phát hành dưới dạng NFT và ngoài ra còn nhận được airdrop 30.000 DLC với chuỗi BSC. Airdrop bắt đầu trước khi niêm yết trên sàn giao dịch, với 20% DLC airdrop được mở khóa ngay lập tức và 80% được mở khóa trong 8 tháng. \n (Xem Quy trình Mã thông báo DLC: Mở ví và chọn mạng BSC, Chọn Thêm Mã thông báo và nhập địa chỉ hợp đồng: 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC)',
      text3: 'Các nút không thể được bán lại cho người khác trước ngày 31 tháng 10 năm 2024.',
      text4: '25% token khai thác được mở khóa ngay lập tức, với 75% còn lại được mở khóa dần dần trong 150 ngày.',
      text5: 'Nếu việc khai thác không bắt đầu trong vòng 10 ngày sau khi niêm yết, phần thưởng sẽ được phân phối thông qua airdrop, với airdrop diễn ra mỗi 7 ngày. Quy tắc airdrop giống như quy tắc khai thác, với tổng sản lượng hàng ngày là 4,1 triệu DLC được phân bổ đều dựa trên tổng số nút đã bán. 25% được mở khóa ngay lập tức và 75% được mở khóa dần dần trong 150 ngày. Nếu việc khai thác đã bắt đầu, bạn cần có các máy tương ứng để nhận phần thưởng.',
      text6: 'Đối với các giao dịch mua hơn 20 nút, các hoạt động của nút có thể được ủy quyền. Các nhà đầu tư mua hơn 1.000 nút riêng lẻ sẽ được cung cấp một bộ hỗ trợ đầy đủ để vận hành các nút, cũng như khả năng kết nối với một nhà điều hành hợp tác để giúp vận hành các nút.',
      text7: 'Một máy ảo có thể hỗ trợ 20 nút NFT và thu nhập được nhân lên 20 lần.',
      text8: 'Mua nút DeepLink và tham gia cuộc thi sức mạnh tính toán GPU Orion trị giá 9 triệu USD: '
    },
    question: {
      ques1: 'Tổng cộng có bao nhiêu NFT nút được bán?',
      ans1: 'Tổng cộng có 120,000',
      ques2: 'Mỗi NFT nút có giá bao nhiêu?',
      ans2: 'Chia thành 10 cấp, với giá từ 30U đến 155U, được bán trên trang web chính thức cho đến khi bán hết',
      ques3: 'Việc mua NFT nút có phải là thanh toán một lần không?',
      ans3: 'Có, việc mua nút là thanh toán một lần và không yêu cầu phí bổ sung trong tương lai',
      ques4: 'Có airdrop bổ sung nào khi mua NFT nút không?',
      ans4: 'Có, mỗi chủ sở hữu nút nhận được airdrop 30,000 DLC. Airdrop bắt đầu trước khi niêm yết trên sàn giao dịch, với 20% DLC được mở khóa ngay lập tức và 80% được mở khóa trong 8 tháng',
      ques5: 'Cơ chế khai thác cụ thể là gì?',
      ans5: 'Tổng phần thưởng là 12 tỷ DLC. Tổng thu nhập từ các nút lưu lượng là 4 tỷ DLC và tổng thu nhập từ các nút máy GPU cá nhân là 8 tỷ DLC. Bằng cách mua một NFT nút, bạn có thể tham gia khai thác cả hai loại đồng thời. Phần thưởng trong 4 năm đầu là 1.5 tỷ DLC mỗi năm, giảm một nửa mỗi 4 năm. Tổng phần thưởng hàng ngày là 4.11 triệu DLC, chỉ được phân phối cho các chủ sở hữu NFT nút. 25% số token được khai thác sẽ được mở khóa ngay lập tức, với 75% còn lại được mở khóa dần trong 150 ngày',
      ques6: 'NFT nút đã mua có thể tham gia cả khai thác máy lưu lượng CPU và khai thác máy GPU cá nhân không?',
      ans6: 'Có',
      ques7: 'Nút máy lưu lượng băng thông CPU là gì?',
      ans7: 'Nút máy lưu lượng băng thông CPU đảm nhiệm nhiệm vụ chính là tính toán và chuyển tiếp lưu lượng trong mạng DeepLink. Thông qua mô hình khai thác, DeepLink cho phép nhiều người tham gia hơn và kiếm được phần thưởng. Tổng thu nhập từ các nút lưu lượng là 4 tỷ DLC',
      ques8: 'Phần thưởng tổng cộng cho các máy lưu lượng băng thông trong năm đầu tiên là bao nhiêu?',
      ans8: 'Phần thưởng trong năm đầu tiên là 500 triệu DLC, với khoảng 1.37 triệu DLC được thưởng hàng ngày',
      ques9: 'Cách tính mối quan hệ giữa thu nhập của mỗi nút máy lưu lượng băng thông CPU và băng thông?',
      ans9: 'Băng thông cơ bản là băng thông uplink 10Mb. Giá trị băng thông là 10 cho băng thông uplink 10Mb. Băng thông chỉ có hiệu lực trong các bội số của 10, ví dụ, băng thông uplink 45Mb được tính là 40 theo giá trị băng thông. Băng thông tăng 30% theo sử dụng thực tế. Cuối cùng, thu nhập được chia đều theo giá trị băng thông thực tế',
      ques10: 'Yêu cầu cấu hình của các nút máy lưu lượng băng thông có cao không? Có thông tin cấu hình nào không? Làm thế nào để tham gia khai thác?',
      ans10: 'Yêu cầu cấu hình không cao. vCPU 1C + 2G bộ nhớ + 50G ổ cứng. Chi phí thuê trên AWS khoảng 10 USD mỗi tháng, với băng thông được tính phí theo sử dụng thực tế. Sau khi thuê một máy ảo CPU trên AWS, cài đặt chương trình khai thác nút lưu lượng DeepLink, tạo địa chỉ ví, chuyển NFT nút vào ví và kích hoạt chức năng khai thác để tham gia khai thác',
      ques11: 'Nút máy GPU cá nhân là gì?',
      ans11: 'Các cá nhân có thể cung cấp máy tính GPU của mình để tham gia khai thác. Máy GPU cung cấp dịch vụ hạ tầng chơi game đám mây cho Giao thức DeepLink. Tổng thu nhập từ các nút máy GPU cá nhân là 8 tỷ DLC',
      ques12: 'Phần thưởng tổng cộng cho các nút máy GPU cá nhân trong năm đầu tiên là bao nhiêu?',
      ans12: 'Phần thưởng trong năm đầu tiên là 1 tỷ DLC, với khoảng 2.74 triệu DLC được thưởng hàng ngày',
      ques13: 'Yêu cầu cấu hình của các máy GPU cá nhân là gì? Làm thế nào để tham gia khai thác?',
      ans13: 'Yêu cầu cấu hình của các máy GPU: Tần số CPU không dưới 3.0GHz, GPU từ series Nvidia Geforce: 20xx, 30xx, 40xx, và ít nhất 16G bộ nhớ'
    }
  },
  BandWidth: {
    "title": "Quy tắc khai thác băng thông lưu lượng",
    "text1": "Khai thác băng thông lưu lượng có tổng cộng 4 tỷ DLC thưởng, 500 triệu DLC mỗi năm trong bốn năm đầu, giảm một nửa mỗi bốn năm.",
    "cont2": {
      "title": "Quy tắc tham gia",
      "text1_1": "Cần sở hữu NFT nút (mỗi nút tặng 30,000 DLC, trị giá 90 đô la, tìm hiểu thêm về nút: ",
      "text1_2": "). Một máy tính CPU có thể giữ nhiều nút, số lượng token thưởng tương ứng với số lượng nút. Một máy chỉ có thể giữ tối đa 20 NFT nút.",
      "text2_1": "Yêu cầu tối thiểu đối với máy chủ CPU: \n Các tham số của máy chủ tính toán phải đáp ứng các yêu cầu tối thiểu",
      "text3_1": "CPU",
      "text3_2": "vCPU 1C",
      "text4_1": "Bộ nhớ",
      "text4_2": "2G",
      "text5_1": "Ổ cứng:",
      "text5_2": "Ổ cứng 50G",
      "text6_1": "Băng thông mạng:",
      "text6_2": "Băng thông cơ bản là 10Mb tải lên; khuyến nghị tăng thêm 1C CPU và 2G bộ nhớ cho mỗi 200M băng thông tăng thêm"
    },
    cont3: {
      title: 'Quy tắc khu vực khai thác lưu lượng',
      text: 'Khai thác lưu lượng sẽ chia toàn cầu thành khoảng 130 khu vực, mỗi khu vực được phân bổ số lượng thưởng DLC khác nhau, công thức tính thưởng khu vực là: thưởng khu vực = giá trị khu vực * thưởng hàng ngày / tổng giá trị khu vực',
      text1: 'Danh sách các khu vực như sau:',
      text2: 'Châu Á',
      text3: 'Châu Mỹ',
      text4: 'Châu Âu',
      text5: 'Châu Phi',
      text6: 'Châu Đại Dương',
      li_other: 'Khác',
      li1: 'Trung Quốc',
      li1_1: 'Bắc Trung Quốc',
      li1_2: 'Đông Bắc Trung Quốc',
      li1_3: 'Đông Trung Quốc',
      li1_4: 'Trung Trung Quốc',
      li1_5: 'Nam Trung Quốc',
      li1_6: 'Tây Nam Trung Quốc',
      li1_7: 'Tây Bắc Trung Quốc',
      li1_8: 'Đài Loan, Trung Quốc',
      li1_9: 'Hồng Kông, Trung Quốc',
      li2: 'Ấn Độ',
      li2_1: 'Uttar Pradesh',
      li2_2: 'Maharashtra',
      li2_3: 'Bihar',
      li2_4: 'Indonesia',
      li2_5: 'Pakistan',
      li2_6: 'Bangladesh',
      li2_7: 'Nhật Bản',
      li2_8: 'Philippines',
      li2_9: 'Việt Nam',
      li2_10: 'Thổ Nhĩ Kỳ',
      li2_11: 'Thái Lan',
      li2_12: 'Hàn Quốc',
      li2_13: 'Malaysia',
      li2_14: 'Ả Rập Xê Út',
      li2_15: 'Các Tiểu vương quốc Ả Rập Thống nhất',

      li3: 'Hoa Kỳ',
      li3_1: 'California',
      li3_2: 'Texas',
      li3_3: 'Florida',
      li3_4: 'New York',
      li3_5: 'Pennsylvania',
      li3_6: 'Illinois',
      li3_7: 'Ohio',
      li3_8: 'Georgia',
      li3_9: 'Michigan',
      li3_10: 'Bắc Carolina',
      li3_11: 'Các khu vực khác của Hoa Kỳ',
      
      li4_1: 'Mexico',
      li4_2: 'Canada',
      li4_3: 'Brazil',
      li4_4: 'Colombia',
      li4_5: 'Argentina',
      
      li5: 'Nga',
      li5_1: 'Moskva',
      li5_2: 'Saint Petersburg',
      li5_3: 'Các khu vực khác của Nga',
      li5_4: 'Đức',
      li5_5: 'Vương quốc Anh',
      li5_6: 'Pháp',
      li5_7: 'Ý',
      li5_8: 'Tây Ban Nha',
      li5_9: 'Hà Lan',
      li5_10: 'Thụy Sĩ',
      
      li6_1: 'Nigeria',
      li6_2: 'Ai Cập',
      li6_3: 'Nam Phi',
      
      li7_1: 'Úc',
    },
    cont5: {
      title: 'Cơ chế Thưởng (Dựa trên chuỗi công khai DBC AI tương thích EVM DLC, Địa chỉ trình duyệt: https://test.dbcscan.io/)',
      text1: 'Trong bốn năm đầu, phần thưởng đào mỗi năm là 500 triệu DLC, hàng ngày là 1,369 triệu DLC. Mỗi khu vực được phân bổ phần thưởng DLC dựa trên tỷ lệ trọng số. Nếu một khu vực không có máy, phần thưởng DLC cho khu vực đó sẽ tự động bị hủy và không được phân bổ cho ai.',
      text2: 'Tổng số token thưởng mỗi ngày cho mỗi khu vực trong hệ thống này là cố định. Phần thưởng mà một máy nhận được phụ thuộc vào băng thông của máy, thời gian đào hiệu quả Y, và số lượng token đã cược. Càng nhiều token cược thì phần thưởng càng lớn, nhưng mối quan hệ tăng không tuyến tính. Cơ sở thưởng cho 0-10,000 đồng là như nhau; phần thưởng tương quan với một hàm số khi vượt quá 10,000 đồng.',
      text3: 'Nếu băng thông lưu lượng của máy đang được sử dụng, giá trị sức mạnh tính toán tăng 30%, và số lượng NFT nút là mối quan hệ nhân với giá trị sức mạnh tính toán. Hiện tại, mỗi máy có thể giữ tối đa 20 NFT nút.',
      text4: 'Chỉ những máy có NFT nút mới đủ điều kiện nhận thưởng trực tuyến.',
      text5: 'Hiện tại, một máy có thể liên kết tối đa 20 NFT nút, số lượng NFT càng nhiều thì phần thưởng càng lớn, thể hiện mối quan hệ tuyến tính.',
      text6: 'Trong 300 ngày đầu, một máy có thể liên kết tối đa 20 NFT nút.',
      text7: 'Trong 600 ngày đầu, một máy có thể liên kết tối đa 10 NFT nút.',
      text8: 'Từ 600 đến 900 ngày, mỗi máy có thể liên kết tối đa 5 NFT nút.',
      text9: 'Từ 900 đến 1200 ngày, mỗi máy có thể liên kết tối đa 2 NFT nút.',
      text10: 'Từ 1200 đến 1800 ngày, mỗi máy có thể liên kết tối đa 1 NFT nút.',
      text11: 'Sau 1800 ngày, mỗi NFT nút có thể liên kết với 2 máy.',
      text12: 'Sau 2400 ngày, mỗi NFT nút có thể liên kết với 4 máy.',
      text13: 'Tính toán phần thưởng:',
      text14: 'R: Tổng số token thưởng hàng ngày (giá trị cố định)',
      text15: 'Số lượng token đã cược của máy thứ i',
      text16: 'Giá trị sức mạnh tính toán của máy thứ i',
      text17: 'Thời gian đào hiệu quả của máy thứ i',

      text18: 'Trong đó N là tổng số máy tham gia đào',
      text19: 'Phần thưởng cho 100.000 đồng là 1.23026 lần so với 10.000 đồng, tăng khoảng 23.03%',
      text20: 'Phần thưởng cho 1 triệu đồng là 1.46052 lần so với 10.000 đồng, tăng khoảng 46.05%',
      text21: 'Phần thưởng cho việc đặt cọc 10 triệu đồng là 1.69078 lần so với 10.000 đồng, tăng khoảng 69.08%',
      text22: 'Phần thưởng cho việc đặt cọc 100 triệu đồng là 1.92103 lần so với 10.000 đồng, tăng khoảng 92.10%',
      
      title1: 'Yêu cầu Thưởng:',
      text1_1: 'Phần thưởng có thể được yêu cầu một lần mỗi 24 giờ',
      text1_2: 'Nếu ban đầu không có DLC nào được đặt cọc hoặc số lượng đặt cọc ít hơn 10.000, lợi nhuận sẽ được tích lũy dưới dạng DLC đặt cọc cho đến khi đạt 10.000 DLC',
      text1_3: '1) Tiền thuê sẽ được tích lũy như vốn đặt cọc cho đến khi đạt 10.000 DLC',
      text1_4: '2) Thời gian thuê tối thiểu là 2 giờ, không giới hạn thời gian thuê tối đa; khi thời gian sử dụng còn lại ít hơn 1 giờ, máy không thể được cho thuê và không được tính vào phần thưởng trực tuyến. Thợ mỏ có thể tăng thời gian bất cứ lúc nào, nhưng mỗi lần tăng phải ít nhất 2 giờ',
      
      title2: 'Công thức Tính Doanh Thu DLC Hàng Ngày cho Máy: DLC được tính theo giá 0.003U,',
      text2_1: 'Băng thông máy CPU của tôi:',
      text2_2: 'Số lượng NFT nút:',
      text2_3: 'Khu vực:',
      text2_4: 'Số lượng DLC đã đặt cọc:',
      text2_5: 'Tổng băng thông:',
      text2_6: '(Tính toán dựa trên trung bình 10 NFT, và trung bình 10.000 DLC đã đặt cọc)',
      text2_7: 'Vui lòng chọn',
      title3: 'Thu nhập từ phần thưởng trên chuỗi: {dlc_num} DLC (tương đương {usdt_num} USD với giá DLC hiện tại: 0.003 USD)',
      title4: 'Cơ chế Tố cáo và Xác minh (Dựa trên DLC tương thích EVM của Chuỗi Công cộng DBC AI)',
      text4_1: 'Máy có cơ chế phát hiện thường trực của Chuỗi Công cộng DBC AI, báo cáo thông tin cho cụm phát hiện phân tán DBC để xác định máy có trực tuyến hay không; nếu không trực tuyến, phần thưởng trực tuyến sẽ bị trừ. Nếu có sự gián đoạn trong quá trình sử dụng của người dùng, DLC sẽ bị trừ từ người dùng. Giới hạn phạt:',
      text4_2: '1) Nếu không có đồng tiền nào được đặt cọc, chỉ có phần thưởng tích lũy được phạt, tối đa 10.000 DLC',
      text4_3: '2) Nếu có DLC được đặt cọc, tối đa 10.000 DLC có thể bị phạt',
    }
  },
  
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: 'Giao thức trò chơi đám mây AI phi tập trung',
      title2: 'Xây dựng cơ sở hạ tầng kết xuất độ trễ cực thấp cho Cloud Gaming',
      btn1: 'Vào PinkSale',
      btn2: 'Tải xuống Whitepaper',
      btn3: 'Tải xuống Pitch Deck',
      btn4: 'Bán công khai DeepLink',
      btn5: 'Chào mừng đến với Phần thưởng Node',
      btn6: 'Tải xuống Client',
      desc1: 'Hỗ trợ DeepLink để nhận Token DLC',
      desc2: '100% An toàn và Đã xác minh',
      desc3: 'Chương trình NVIDIA Inception'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: 'Hiệu suất được hiển thị là ví dụ của hai máy tính cách nhau 50km, máy điều khiển là một chiếc laptop thông thường, máy bị điều khiển là một máy GPU cao cấp, hiển thị một hình ảnh động của trò chơi, với dạng hình động kéo dài khoảng 3-5 giây.',
      btn: 'Xem video để tìm hiểu thêm'
    },
    cont3: {
      text1: 'Độ trễ cực thấp',
      text2: 'Độ phân giải cao',
      text3: 'Độ trễ cực thấp',
      text4: 'Không giật, không ngắt kết nối, độ ổn định cao',
      desc1: 'DeepLink Protocol thực hiện những đột phá công nghệ mang tính cách mạng, mang lại trải nghiệm hoàn hảo không thể sánh kịp.',
      desc2: 'Các thành viên đội ngũ công nghệ cốt lõi bắt đầu tham gia ngành game đám mây từ năm 2011, với các công ty tham gia như Cisco, Intel, Nvidia, Huawei, ShunNet và nhiều công ty khác. Sau hơn 10 năm tích lũy nghiên cứu phát triển, DeepLink đã ra đời.'
    },
    cont16: {
      title1_1: 'DeepLink Protocol cung cấp dịch vụ công nghệ độ trễ cực thấp cho game đám mây',
      title1_2: 'Cung cấp dịch vụ công nghệ độ trễ cực thấp cho game đám mây',
      title2: 'Hiện tại, các sản phẩm phát triển dựa trên DeepLink Protocol có những ứng dụng sau, và hơn 20 ứng dụng khác đang trong quá trình phát triển',
      text1: 'Điều khiển từ xa',
      text2: 'Game đám mây & Nền tảng game đám mây',
      text3: 'Quán net đám mây',
      text4: 'Cloud XR',
      text5: 'XXX Game VR đám mây',
      text6: 'YYY Nền tảng game đám mây'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: 'Sử dụng công cụ điều khiển từ xa làm điểm vào lưu lượng',
      title2: 'Phần mềm DeepLink sử dụng công cụ điều khiển từ xa làm điểm vào lưu lượng và các nền tảng game đám mây và quán net đám mây làm phương tiện kiếm tiền',
      text1_1: '900,000+',
      text1_2: 'Số người dùng hoạt động hàng tháng',
      text2_1: '110+',
      text2_2: 'Số quốc gia cung cấp dịch vụ',
      text3_1: '2,500,000+',
      text3_2: 'Tổng số người dùng',
      text4_1: '300,000+',
      text4_2: 'Số người dùng hoạt động hàng ngày',
      desc1: 'Công cụ điều khiển từ xa',
      desc2: 'Game đám mây và Cloud XR',
      desc3: 'Quán net đám mây',
      btn1: 'YouTube KOL cấp độ 1',
      btn2: 'YouTube KOL cấp độ 2',
      btn3: 'YouTube KOL cấp độ 3',
      btn4: 'YouTube KOL cấp độ 4'
    },
    cont17: {
      title1: 'Mô hình kinh doanh của DeepLink Protocol',
      text1: 'Mô hình hoa hồng',
      text2: 'Mô hình License',
      desc1: 'Mỗi giao dịch game đám mây, DeepLink Protocol sẽ thu phí hoa hồng từ 20-30%, phí hoa hồng sẽ được thanh toán bằng DLC và 100% DLC sẽ bị hủy ngay lập tức.',
      desc2: 'Đối với các ứng dụng như quán net đám mây và khách sạn e-sport đám mây, mỗi ứng dụng sẽ phải trả phí License là 6 USD mỗi tháng cho mỗi thiết bị máy tính.'
    },
    cont5: {
      title1: 'Cung cấp lưu lượng mạng nhàn rỗi thông qua công nghệ blockchain',
      desc1: 'Kết nối nút',
      desc2_1: 'Thiết bị cần thiết',
      desc2_2: 'Chuyển tiếp lưu lượng',
      desc3: 'Mỏ khai thác toàn cầu có thể cung cấp các nút lưu lượng thông qua việc mua NFT và nhận phần thưởng DLC token',
      desc3_2: '',
      title2: 'Cung cấp nút lưu lượng',
      text2: 'Mỏ khai thác cung cấp các nút lưu lượng', 
      title3: 'Mặt người dùng',
      text3: 'Người dùng cá nhân'
    },
    cont6: {
      title1: 'Tăng cường đáng kể khả năng cạnh tranh của DeepLink trên thị trường',
      title2: 'Thông qua công nghệ blockchain',
      desc1: 'Thông qua hợp đồng thông minh, đảm bảo các nhà cung cấp GPU cung cấp dịch vụ ổn định bằng cách stake tiền điện tử',
      desc2: 'Mọi người đều có thể đóng góp vào mạng lưới và nhận phần thưởng, chẳng hạn như cung cấp máy chủ GPU hoặc cung cấp các nút lưu lượng',
      desc3: 'Việc thu hút thợ mỏ cung cấp các nút chuyển tiếp lưu lượng giúp phân phối rộng rãi các nút mà không cần công ty tập trung đầu tư vào việc triển khai máy chủ, làm cho hệ thống phân cấp hơn và công ty không phải chịu chi phí máy chủ',
      desc4: 'Thông qua hợp đồng thông minh và stake tiền điện tử, các nhà đầu tư có thể thoát ra và nhận được lợi nhuận trong vòng khoảng 1 năm, thay vì phải chờ đợi 5-10 năm',
      desc5: 'Người dùng có thể tham gia vào phần lợi nhuận từ sự phát triển của dự án, giúp tăng cường việc lan truyền',
      desc6: 'Người dùng có thể thực hiện thanh toán tự do không biên giới',
      text1: 'Stake',
      text2: 'Mọi người tham gia',
      text3: 'Không tốn chi phí',
      text4: 'Rút ngắn thời gian lợi nhuận',
      text5: 'Không biên giới',
      text6: 'Người dùng nhận phần thưởng'
    },
    cont7: {
      title1: 'Chúng tôi đang thực hiện một cuộc tấn công giảm chiều công nghệ Web3 lên công nghệ Web2',
      desc1: 'Để đạt trải nghiệm chơi game đám mây với độ trễ thấp, ngoài công nghệ, máy tính GPU cần phải cách người dùng không quá 50km và phủ sóng toàn cầu cần hơn 50.000 trung tâm dữ liệu.',
      title2: 'Nền tảng game đám mây tập trung truyền thống',
      title3: 'Nền tảng game đám mây dựa trên công nghệ blockchain của DeepLink',
      text1: 'Nền tảng tự mua máy móc, chi phí lớn, thời gian hoàn vốn không xác định.',
      text2: 'Phạm vi máy hẹp, không thể phủ sóng toàn cầu.',
      text3: 'Công nghệ tập trung không thể hỗ trợ kết nối hàng chục nghìn trung tâm dữ liệu.',
      text4: 'Thợ mỏ và chủ quán net cung cấp máy chủ GPU, nhận thưởng token giúp rút ngắn chu kỳ hoàn vốn của thợ mỏ, và giảm áp lực mua sắm máy móc cho nền tảng.',
      text5: 'Số lượng thợ mỏ và quán net lớn, có thể phủ sóng toàn cầu.',
      text6: 'Công nghệ blockchain phân tán hỗ trợ hơn 100.000 trung tâm dữ liệu và hơn 10 triệu điểm máy chủ GPU.'
    },
    cont8: {
      title1: 'Kịch bản sử dụng token DeepLink',
      text1: 'Mua DLC',
      text2: 'Thanh toán DLC', 
      text3: 'Thanh toán USDT',
      text4: 'Thanh toán DLC',
      text5: 'Tiêm DLC và USDT vào DEX', 
      text6: 'Bán DLC',
      text7: 'Người dùng 1',
      text8: 'Phần mềm DeepLink', 
      text9: 'Trong đó 40%DLC sẽ bị tiêu hủy trực tiếp trên chuỗi',
      text10: '1. Mua NFT',
      text11: '2. Mua thời gian chơi game đám mây', 
      text12: '3. Thuê thời gian máy tại quán net',
      text13: '4. Mua thời gian chơi game chia sẻ cá nhân', 
      text14: 'Trong đó 40%USDT sẽ được sử dụng để mua lại DLC trên DEX sau đó tiêu hủy', 
      text15: 'Mua NFT',
      text16: 'DEX và CEX',
      text17: 'Các nền tảng giao dịch khác',
      text18: 'Thợ mỏ',
      text19: 'Phần thưởng khai thác tính thanh khoản',
      text20: 'Phần thưởng trong 2 năm, mỗi năm 5 tỷ DLC'
    },
    cont8_new: {
      text7: 'Người dùng cá nhân',
      text12: '3. Thời gian thuê máy tại quán net',
      text21: 'Người dùng doanh nghiệp',
      text22: 'Giao thức DeepLink',
      text23: '1. Mua giấy phép',
      text24: '2. Phí 20-30%',
      text25: '100% DLC sẽ được tiêu hủy trực tiếp trên chuỗi'
    },
    cont8_new1: {
      title: 'Các YouTubers hàng đầu ủng hộ dự án của chúng tôi',
      text1: '',
      text2: 'Người dùng doanh nghiệp',
      text3: 'Giao thức DeepLink',
      text4: '1. Mua giấy phép',
    },
    cont9: {
      title1: 'Phần thưởng đào mỏ tính thanh khoản Token DeepLink',
      text1: 'Người dùng 1',
      text2: 'Tiêm DLC và USDT vào DEX', 
      text3: 'Phần thưởng đào mỏ tính thanh khoản',
      text4: 'Thưởng trong 2 năm, mỗi năm 5 tỷ DLC',
      text5: 'Tiêm tính thanh khoản DLC và USDT vào Uniswap và Pancake Swap', 
      text6: 'Phần thưởng hàng ngày, tự động nhận qua NFT tính thanh khoản',
      text7: '80% phần thưởng cho những người cung cấp tính thanh khoản, 20% cho nhà giao dịch',
      text8: 'Mỗi ngày thưởng 13,698,630 DLC'
    },
    cont9_new: {
      title1: 'Phần thưởng đào mỏ tính thanh khoản Token DeepLink',
      text1: '5% DLC cùng với giá trị tương đương của USDT, ETH và BNB.',
      text2: 'Tiêm vào DEX',
      text3: 'Tăng cường tính thanh khoản của DEX',
      text4: '',
      text5: '5% token này sẽ được bán trong quá trình IDO (Initial DEX Offering). Tùy thuộc vào tỷ lệ bán thực tế, cuối cùng tất cả sẽ được tiêm vào DEX.',
      text6: 'Tiêm tính thanh khoản của DLC và USDT vào các DEX như Uniswap, SushiSwap, Pancake Swap',
      text7: 'Phần tính thanh khoản này sẽ được khóa trong 5 năm và sau đó sẽ được sử dụng cho sự phát triển lâu dài của dự án.',
      text8: ''
    },
    cont10: {
      title: 'Lộ trình & Kế hoạch',
      desc1: 'Tháng 10 năm 2020',
      text1: 'Khởi động dự án sản phẩm DeepLink',
      desc2: 'Tháng 3 năm 2021',
      text2: 'Nhận đầu tư vòng hạt giống',
      desc3: 'Tháng 8 năm 2021',
      text3: 'Phát hành phiên bản phát triển nội bộ đầu tiên của DeepLink, độ trễ kỹ thuật đạt 3ms, hỗ trợ bàn phím và chuột chơi game',
      desc4: 'Tháng 5 năm 2022',
      text4: 'Phát hành phiên bản thử nghiệm tính năng điều khiển từ xa đầu tiên của DeepLink, hỗ trợ hiển thị 3K 144HZ',
      desc5: 'Tháng 8 năm 2022',
      text5: 'Phát hành phiên bản thử nghiệm tính năng điều khiển từ xa thứ hai của DeepLink, hỗ trợ màn hình ảo',
      desc6: 'Tháng 10 năm 2022',
      text6: 'Ra mắt phiên bản chính thức của DeepLink, hỗ trợ tiếng Hàn, tiếng Trung và tiếng Anh, và thành lập công ty DeepCloudX tại Singapore để vận hành DeepLink',
      desc7: 'Tháng 12 năm 2022',
      text7: 'Nhận đầu tư vòng thiên thần',
      desc8: 'Tháng 4 năm 2023',
      text8: 'Hợp tác với chuỗi quán net lớn nhất Hàn Quốc, xây dựng phòng mẫu thực tế tại quán net, thử nghiệm quán net đám mây, nhận được đánh giá tích cực từ người dùng',
      desc9: 'Tháng 6 năm 2023',
      text9: 'Lượt tải xuống DeepLink vượt quá 200,000, người dùng ở hơn 100 quốc gia, hỗ trợ hiển thị 8K 60Hz',
      desc10: 'Tháng 1 năm 2021',
      text10: 'Xác định hướng nghiên cứu và phát triển của DeepLink và bắt đầu thiết kế và phát triển sản phẩm',
      desc11: 'Tháng 4 năm 2021',
      text11: 'Đội ngũ cốt lõi được thành lập hoàn chỉnh, các thành viên đội ngũ cốt lõi đã tham gia ngành công nghiệp game đám mây từ năm 2011, có nguồn gốc từ các công ty như Cisco, IntelNvidia, Huawei, Shunwang',
      desc12: 'Tháng 3 năm 2022',
      text12: 'DeepLink bắt đầu thử nghiệm trong lĩnh vực quán net, độ trễ kỹ thuật giảm xuống 1ms',
      desc13: 'Tháng 7 năm 2022',
      text13: 'Phát hành phiên bản thử nghiệm tính năng điều khiển từ xa thứ ba của DeepLink, hỗ trợ màn hình riêng tư, sao chép tệp từ xa',
      desc14: 'Tháng 9 năm 2022',
      text14: 'Phát hành phiên bản thử nghiệm tính năng điều khiển từ xa thứ tư của DeepLink, hỗ trợ đa luồng, hỗ trợ màu sắc thực 444',
      desc15: 'Tháng 11 năm 2022',
      text15: 'Lắp đặt 10 máy tính mini tại Seoul và xây dựng môi trường quán net ảo để thử nghiệm quán net đám mây',
      desc16: 'Tháng 2 năm 2023',
      text16: 'Lượt tải xuống DeepLink vượt quá 100,000, người dùng ở hơn 50 quốc gia, hỗ trợ hiển thị 4K 200HZ',
      desc17: 'Tháng 5 năm 2023',
      text17: 'Lượt tải xuống DeepLink vượt quá 150,000, người dùng ở hơn 80 quốc gia, hỗ trợ chức năng ví DLC, hỗ trợ tiếng Việt, tiếng Nhật'
    },
    cont11: {
      title: 'Kế hoạch phát triển',
      desc1: 'Tháng 7 năm 2023',
      text1: 'Hỗ trợ thêm danh sách thiết bị và chức năng phần thưởng chia sẻ',
      desc2: 'Tháng 8 năm 2023',
      text2: 'Ra mắt quán net đám mây đầu tiên tại Seoul',
      desc3: 'Tháng 10 năm 2023',
      text3: 'Hỗ trợ chức năng phần thưởng chia sẻ',
      desc4: 'Tháng 12 năm 2023',
      text4: 'Hỗ trợ chức năng NFT',
      desc5: 'Q1 2024',
      text5: 'Hỗ trợ chức năng chia sẻ game cho phiên bản WEB và thiết lập quan hệ đối tác, mục tiêu là lượt tải xuống DeepLink vượt quá 500,000 lần',
      desc6: 'Q2 2024',
      text6: 'Mục tiêu là lượt tải xuống DeepLink vượt quá 1,000,000 lần và hợp tác với 10 quán net đám mây',
      desc7: 'Q3 2024',
      text7: 'Mục tiêu đạt hơn 2 triệu lượt tải xuống cho DeepLink và hợp tác với 30 quán internet đám mây',
      desc8: 'Q4 2024',
      text8: 'Mục tiêu đạt hơn 2,5 triệu lượt tải xuống cho DeepLink và hợp tác với 40 quán internet đám mây',
      desc9: 'Q1 2025',
      text9_1: 'Nhân vật ảo AI Agent trò chơi: Phát triển nhân vật ảo có thể kích hoạt bất cứ lúc nào',
      text9_2: 'Cho phép AI Agent nhận diện giao diện trò chơi của người dùng trong thời gian thực và cung cấp hướng dẫn chơi trò chơi mục tiêu',
      text9_3: 'Mở rộng tính năng quán internet: Hỗ trợ thuê máy tính không sử dụng trong các quán internet, cho phép chia sẻ tài nguyên',
      text9_4: 'Hỗ trợ chơi game đám mây: Thực hiện tính năng chơi game đám mây ban đầu để mang lại trải nghiệm chơi game hiệu quả và thuận tiện cho người dùng',
      text9_5: 'Các cột mốc:',
      text9_6: 'Đạt hơn 3 triệu người dùng',
      text9_7: 'GPU chia sẻ vượt qua 1000',
      text9_8: 'Hợp tác với hơn 60 quán internet đám mây',
      desc10: 'Q2 2025',
      text10_1: 'Chức năng điều khiển bằng giọng nói: Triển khai khả năng ra lệnh bằng giọng nói để người dùng có thể hướng dẫn AI Agent thực hiện các thao tác chơi game, nâng cao hiệu quả tương tác',
      text10_2: 'Cải tiến tính năng quán internet: Tối ưu hóa tính năng thuê máy tính không sử dụng trong quán internet để nâng cao hiệu quả tài nguyên và ổn định',
      text10_3: 'Tối ưu hóa chơi game đám mây: Nâng cao hiệu suất chơi game đám mây, giảm độ trễ và tăng hiệu quả tài nguyên',
      text10_4: 'Các cột mốc:',
      text10_5: 'Đạt hơn 4 triệu người dùng',
      text10_6: 'GPU chia sẻ vượt qua 2000',
      text10_7: 'Hợp tác với hơn 100 quán internet đám mây',
      desc11: 'Q3 2025',
      text11_1: 'Các thao tác trò chơi AI Agent: Cho phép AI Agent hỗ trợ người dùng trong các thao tác chơi game cơ bản như hướng dẫn nhiệm vụ và thu thập tài nguyên',
      text11_2: 'Các cột mốc:',
      text11_3: 'Đạt hơn 5 triệu người dùng',
      text11_4: 'GPU chia sẻ vượt qua 3000',
      text11_5: 'Hợp tác với hơn 150 quán internet đám mây',
      desc12: 'Q4 2025',
      text12_1: 'Các thao tác trò chơi trung cấp của AI Agent: Phát triển khả năng cho nhân vật ảo thực hiện các nhiệm vụ trò chơi khó vừa, như tối ưu hóa chiến lược và hỗ trợ chiến đấu',
      text12_2: 'Các cột mốc:',
      text12_3: 'Đạt hơn 6 triệu người dùng',
      text12_4: 'GPU chia sẻ vượt qua 4000',
      text12_5: 'Hợp tác với hơn 200 quán internet đám mây',
      desc13: '2026',
      text13_1: 'Hoạt động trò chơi nâng cao của AI Agent:',
      text13_2: 'Cho phép nhân vật ảo chủ động thực hiện các hoạt động trò chơi nâng cao, bao gồm lập kế hoạch chiến lược toàn cầu và hoàn thành các nhiệm vụ khó, nâng cao trải nghiệm chơi game của người dùng',
      text13_3: 'Mục tiêu đạt được hơn 15 triệu lượt tải xuống cho DeepLink, 50.000 máy tính chia sẻ và hợp tác với 800 quán internet đám mây',
    },
    cont12: {
      text1: 'Đầu tư',
      text1_1: 'Vòng hạt giống',
      desc1_1: '2 tháng khóa, mở khóa tuyến tính trong 20 tháng',
      text1_2: 'Vòng thiên thần',
      desc1_2: '1 tháng khóa, mở khóa tuyến tính trong 10 tháng',
      text1_3: 'Vòng A',
      desc1_3: '1 tháng khóa, mở khóa tuyến tính trong 10 tháng',
      text1_4: 'Bán công khai',
      desc1_4: '40% mở khóa trước TGE, phần còn lại bắt đầu mở khóa vào ngày 30 tháng 8 với tuyến tính trong 6 tháng',
      text2: 'Đội ngũ',
      desc2: '2 tháng khóa, mở khóa tuyến tính trong 20 tháng',
      text3: 'Airdrop',
      desc3: '20% mở khóa trước TGE, mở khóa tuyến tính trong 8 tháng',
      text4: 'Bán NFT',
      desc4: '20% mở khóa trước TGE, mở khóa tuyến tính trong 8 tháng',
      text5: 'Khai thác',
      text5_1: 'GPU',
      desc5_1: '16% dành cho người khai thác cung cấp sức mạnh GPU, giảm một nửa sau mỗi 4 năm. 25% phần thưởng khai thác được mở khóa ngay lập tức, phần còn lại mở khóa theo tuyến tính trong 150 ngày.',
      text5_2: 'Băng thông',
      desc5_2: '4% dành cho khai thác băng thông, giảm một nửa sau mỗi 4 năm. 25% phần thưởng được mở khóa ngay lập tức, phần còn lại mở khóa theo tuyến tính trong 150 ngày.',
      text5_3: 'Cuộc đua',
      desc5_3: 'Không có thời gian khóa, 10% phần thưởng được mở khóa ngay lập tức, phần còn lại mở khóa theo tuyến tính trong 180 ngày.',
      text6: 'Quỹ',
      desc6: '6 tháng khóa, mở khóa tuyến tính trong 8 quý',
      text7: 'Cố vấn pháp lý',
      desc7: '1 tháng khóa, mở khóa tuyến tính trong 10 tháng',
      text8: 'Hệ sinh thái',
      desc8: '20% mở khóa trước TGE, mở khóa tuyến tính trong 8 tháng',
      text9: 'Staking',
      text9_1: 'Dài hạn',
      desc9_1: 'Không có thời gian khóa, mở khóa tuyến tính trong 180 ngày',
      text9_2: 'Ngắn hạn',
      desc9_2: 'Không có thời gian khóa, mở khóa tuyến tính trong 90 ngày',
      text10: 'Tổng cộng',
      text11: 'Số lượng (tỷ):',
      text12: 'Mô tả:',
      text13: 'Trong lưu thông (tỷ):',
      text14: 'Chưa lưu thông (tỷ):',
      text15: '100 tỷ'
    },
    cont13: {
      title: 'Đội ngũ và cố vấn',
      intro1: 'CEO DeepLink',
      intro2: 'Đồng sáng lập DeepLink',
      intro3: 'Giám đốc điều hành DeepLink (COO)',
      intro4: 'Giám đốc kinh doanh DeepLink (CBO) kiêm lãnh đạo thị trường Ấn Độ',
      intro5: 'Cố vấn',
      intro6: 'Cố vấn',
      intro7: 'Cố vấn',
      intro8: 'Đồng sáng lập kiêm Giám đốc marketing',
      user1_1: 'Doanh nhân liên tục với 13 năm kinh nghiệm về trí tuệ nhân tạo và 7 năm phát triển công nghệ blockchain và mạng tính toán phân tán.',
      user1_2: 'Tốt nghiệp chuyên ngành khoa học máy tính tại Đại học Hải Dương Trung Quốc năm 2008.',
      user1_3: 'Năm 2012, ông đã thành lập JuShang Intelligent và phát triển trợ lý AI tiếng Trung đầu tiên trên thế giới "Thông minh 360".',
      user1_4: 'Trong ba năm, phần mềm này đã được tải xuống hơn 17 triệu lần. Năm 2014, ông đã ra mắt chiếc loa AI đầu tiên trên thế giới "Loa XiaoZhi".',
      user1_5: 'Vào tháng 5 năm 2017, ông đã thành lập DeepBrainChain, phục vụ hàng trăm khách hàng trong lĩnh vực AI, trò chơi đám mây và quán net đám mây.',
      user2_1: 'Doanh nhân liên tục, chuyên gia đầu ngành về trò chơi đám mây. Trước đây là giám đốc viện nghiên cứu trò chơi đám mây của Shunwang, phụ trách phát triển công nghệ.',
      user2_2: 'Tốt nghiệp ngành khoa học máy tính tại Đại học Chiết Giang năm 1999. Làm việc tại LanDe Chiết Giang từ năm 2001 đến 2004 trong lĩnh vực viễn thông.',
      user2_3: 'Năm 2009, ông đã thành lập trình duyệt đa nhân "Sunflower Browser", trình duyệt đầu tiên của Trung Quốc.',
      user2_4: 'Từ năm 2010 đến 2021, ông gia nhập công ty niêm yết Shunwang và thành lập Shunwang Cloud Computer, giữ chức vụ giám đốc viện nghiên cứu Shunwang. Ông cung cấp các giải pháp cho quán net đám mây và quản lý trò chơi đám mây, biến công ty mình thành công ty đầu tiên tại Trung Quốc cung cấp giải pháp trò chơi đám mây.',

      user3_1: 'Nền tảng giáo dục: Tốt nghiệp ngành Văn học Trung Quốc tại Đại học Bắc Kinh năm 2007',
      user3_2: '2023: Giám đốc điều hành của DeepLink',
      user3_3: '2022-2023: Giám đốc điều hành của DeepBrain Chain',
      user3_4: '2019-2021: Lãnh đạo thị trường quốc tế tại CELLOGIN',
      user3_5: '2018: Giám đốc điều hành của FLOW FACTORY',
      
      user4_1: 'Giáo dục và Chứng chỉ: - Tốt nghiệp cử nhân quản lý kinh doanh tại Đại học Mysore năm 2020.',
      user4_2: '- Chứng chỉ Tiếp thị số.',
      user4_3: '- Chứng chỉ Tối ưu hóa Công cụ Tìm kiếm (SEO).',
      user4_4: '- Chứng chỉ Blockchain.',
      user4_5: 'Kinh doanh và Tiếp thị tại Technic Constructions: - Đảm nhận vai trò kinh doanh và tiếp thị, đạt doanh thu 500.000 USD trong ba tháng.',
      user4_6: "Trợ lý Phát triển Kinh doanh tại BYJU'S: - Làm việc từ năm 2020 đến 2021.",
      user4_7: '- Đóng vai trò chính trong một công ty khởi nghiệp kỳ lân trị giá 10 tỷ USD, thúc đẩy tăng trưởng, thiết lập quan hệ đối tác và tối ưu hóa hoạt động cho các chương trình công nghệ giáo dục.',
      user4_8: 'Đồng sáng lập Space Inn: - Đồng sáng lập một dự án độc lập, tập trung vào phát triển kỹ năng kinh doanh, tư duy chiến lược và nhạy bén trong kinh doanh.',
      user4_9: 'Đầu tư/Giao dịch trên thị trường chứng khoán: - Tích lũy 5 năm kinh nghiệm đầu tư và giao dịch trên thị trường chứng khoán, thể hiện kiến thức chuyên môn trong việc điều hướng và tận dụng các cơ hội đầu tư trên thị trường tài chính.',
      user4_10: 'Thực tập tại các Công ty Doanh nghiệp: - Hoàn thành nhiều đợt thực tập tại các công ty doanh nghiệp khác nhau, thu thập thông tin quý giá về văn hóa doanh nghiệp, quy trình hiệu quả và chiến lược kinh doanh hiệu quả.',
      user4_11: 'Kinh nghiệm trong ngành tiền điện tử: - Tham gia vào nhiều dự án tiền điện tử và NFT, thu được kinh nghiệm quý giá trong ngành.',
      user4_12: '- Đóng góp cho dự án Real-Fi Cardano quan trọng của Empowa, thúc đẩy sự phát triển và tăng trưởng của nó. Đóng góp cho dự án Cardano của Empowa vào năm 2022, thúc đẩy sự tăng trưởng của nó.',
      user4_13: '- Hiện đang hợp tác với DeepLink Cloud, mở rộng chuyên môn trong lĩnh vực tiền điện tử.',

      user5_1: 'Từ năm 2012, giữ chức phó chủ tịch tại Hyunjin ICT, một công ty dịch vụ Internet vạn vật và truyền thông, đã làm việc 11 năm.',
      user5_2: 'Quản lý bán hàng và thị trường, có kinh nghiệm thực hiện nhiều dự án lớn tại các cơ quan công cộng.',
      user5_3: 'Làm việc 14 năm (1998-2012) tại công ty IT lớn nhất Hàn Quốc, Samsung SDS, chuyên về tư vấn chiến lược thông tin, dự án đổi mới kinh doanh và phát triển phần mềm.',
      user5_4: 'Làm việc 3 năm (2017-2019) tại sàn giao dịch tiền điện tử Coinzest, có kinh nghiệm làm việc tại công ty hàng đầu Hàn Quốc và top 10 thế giới.',
      user5_5: 'Người sáng lập Hyunjin Venus, công ty đã điều hành trung tâm mua sắm phúc lợi cho các doanh nghiệp lớn và cơ quan công cộng của Hàn Quốc trong 5 năm.',
      user5_6: 'Có chứng chỉ PMP (Chuyên gia quản lý dự án) và CISA (Kiểm toán viên hệ thống thông tin đăng ký).',
      
      user6_1: 'Nền tảng giáo dục: 2003, Học viện Quản lý Guanghua, Đại học Bắc Kinh',
      user6_2: '2023: CEO của MetABC',
      user6_3: '2020: CEO của HillstoneHub',
      user6_4: '2019: CEO của Hillstone Business Center',
      user6_5: '2019: COO của Hillstone Global (blockchain)',
      user6_6: '2017: Người sáng lập Foundersbridge',
      user6_7: '2017: Đối tác tại Hillstone Partners (quỹ tư nhân)',
      user6_8: '2014: Đồng sáng lập BrightA Consulting',
      user6_9: '2012: Cố vấn cao cấp (Chuyên gia kinh doanh Trung Quốc)',

      user7_1: "Joseph Alexander là người tạo ra ChatDBC.com, một mô hình ngôn ngữ quy mô lớn được tùy chỉnh bởi Deep Brain Chain.",
      user7_2: 'Chuyên môn của anh ấy là phát triển kinh doanh ở Bắc Mỹ cho DeepLink và DBC. ',
      user7_3: "Bạn có thể theo dõi {'@'}ChatDBC trên Twitter để nhận tin tức mới nhất về tất cả DeepLink và DBC.",
     
      user8_1: "Maryna Barysheva là COO của LKI Consulting, một công ty tiếp thị Web3 từng đoạt giải thưởng chuyên về các dự án công nghệ sâu, DeFi, cơ sở hạ tầng và trò chơi.",
      user8_2: "Cô ấy đã mở rộng quy mô thành công các dự án blockchain tại hơn 23 quốc gia, quản lý ngân sách tiếp thị từ 100.000 USD đến 10 triệu USD và tiếp thị cho một công ty khởi nghiệp công nghệ B2B trị giá 100 triệu USD.",
      user8_3: "Chuyên môn của Maryna nằm ở phát triển cộng đồng, nhận thức về thương hiệu và chiến lược GTM toàn cầu.",
      user8_4: "Cô ấy là diễn giả quốc tế, xuất hiện thường xuyên tại các hội nghị về tiền điện tử trên khắp thế giới và là cố vấn ươm tạo cho các dự án Trí tuệ nhân tạo, Công nghệ và Blockchain tại Đại học Helsinki.",
     
      user9_1: "Brando là một cựu chiến binh trong lĩnh vực tiền điện tử. Năm 2016, anh ấy bắt đầu làm việc về tiền điện tử, dẫn đến đợt tăng giá nổi tiếng năm 2017.",
      user9_2: "Anh ấy thành lập Bigger Than Race™️ vào năm 2012 và đổi tên thành như ngày nay vào năm 2017.",
      user9_3: "Trình độ chuyên môn của anh ấy bao gồm chứng nhận Web3/Metaverse thông qua EveryRealm, một quỹ đầu tư mạo hiểm và dẫn đầu về đổi mới và phát triển Metaverse cũng như chứng nhận Blockchain và Bitcoin thông qua IBM.",
      user9_4: "'Trình hướng dẫn mã hóa' BRANDO MURPHY ",
      user9_5: "Tầm nhìn và mục tiêu chính của anh ấy là giúp mọi người đạt được tự do tài chính thông qua công nghệ blockchain và có thể đầu tư và sở hữu cơ sở hạ tầng của nền kinh tế mới của cuộc cách mạng công nghiệp lần thứ tư và thứ năm. Cộng đồng BTR VC đang giúp các thành viên trung thành trên toàn thế giới sở hữu Công nghệ của tương lai.",
      user9_6: "Năm 2024, anh ấy trở thành người đồng sáng lập và giám đốc tiếp thị của DeepLink Protocol, một công nghệ chơi game trên nền tảng đám mây trí tuệ nhân tạo giúp tạo sân chơi bình đẳng cho các game thủ trên toàn thế giới. Los Angeles, California là quê hương của anh ấy.",
     
     
     
      user10_1: "Summer là người sáng lập và chủ trì FireHustle, một công ty truyền thông chuyên đưa tin về các dự án tiền điện tử, chiến lược đầu tư, v.v. Summer có kiến ​​thức nền tảng về giáo dục kinh doanh và kinh nghiệm sâu rộng về phát thanh truyền hình và truyền thông.",
      user10_2: "Về mặt truyền thông, danh mục đầu tư của nó bao gồm kênh YouTube với hơn 44.000 người đăng ký, cộng đồng Discord chuyên dụng với hơn 3.000 thành viên, nhóm thành viên riêng tư, v.v.",
      user10_3: "Về mặt đầu tư, FireHustle đã đầu tư cá nhân vào hơn 50 công ty khởi nghiệp web3 và đóng vai trò là đối tác tiếp thị, gây quỹ và tư vấn cho nhiều dự án tiền điện tử, quỹ đầu tư mạo hiểm, nền tảng khởi nghiệp, tập đoàn, nhóm KOL, v.v.",
     
      user11_1: "Manny là một chuyên gia và người đam mê blockchain với hơn một thập kỷ kinh nghiệm trong ngành và đã đóng góp cho các công ty và sáng kiến ​​hàng đầu kể từ năm 2013.",
      user11_2: "Nền tảng chuyên môn của anh ấy bao gồm: các vị trí nền tảng tại Grayscale, nhóm vận hành tại Coinbase, nhóm tiếp thị tại DYDX và Ledger, và cuối cùng là các vị trí tại Binance US.",
      user11_3: "Giáo dục: Các khóa học liên quan đến Blockchain và tiền điện tử từ các tổ chức có uy tín ở Hoa Kỳ, đặc biệt là Đại học Stanford, bao gồm Blockchain và Tiền điện tử: Giới thiệu toàn diện, Chiến lược Blockchain nâng cao, Chương trình CEO và Chương trình Giám đốc Tiếp thị. Ông có chuyên ngành Tài chính và Quản trị kinh doanh."
    },
    cont14: {
      title: 'Các Tổ Chức Đầu Tư Của Chúng Tôi',
      organ1: 'Quỹ DeepBrain Chain được thành lập vào năm 2017. Tháng 1 năm 2018, token DBC được niêm yết trên sàn giao dịch Huobi. Mainnet đã khởi chạy vào năm 2021. DeepBrain Chain là một mạng lưới tính toán phân tán hiệu suất cao tập trung vào GPU. Tầm nhìn của nó là xây dựng một mạng lưới tính toán hiệu suất cao phân tán có khả năng mở rộng vô hạn dựa trên công nghệ blockchain, trở thành hạ tầng tính toán quan trọng nhất trong kỷ nguyên AI+Metaverse. Chủ yếu cung cấp dịch vụ tính toán GPU cho các lĩnh vực như trí tuệ nhân tạo, game đám mây, và dựng phim. Hiện tại, nó đang phục vụ hàng trăm công ty liên quan đến AI và game đám mây, cùng với hơn 30,000 chuyên gia AI.',
      organ2: 'Gobi Capital là một công ty đầu tư mạo hiểm chuyên nghiệp có trụ sở tại Thượng Hải, Bắc Kinh và Đông Nam Á, chuyên đầu tư vào các dự án công nghệ giai đoạn đầu ở Trung Quốc. Các nhà đầu tư chiến lược của Gobi bao gồm IBM, Sierra Ventures, McGraw-Hill và Steamboat Ventures (bộ phận đầu tư mạo hiểm của Disney). Gobi đã đầu tư vào các startup nổi tiếng như Tuniu, Camera360 và Zhu Yun.',
      organ3: 'Hycons là nền tảng đám mây tính toán GPU phát triển dựa trên công nghệ DBC và được vận hành bởi Hyunjin ICT. Hycons nổi bật với công nghệ mạng phân tán hiệu suất cao dựa trên DBC và công nghệ streaming HD không trễ dựa trên DeepLink. Nền tảng này cung cấp dịch vụ toàn diện bao gồm quản lý thành viên, đăng ký và thanh toán cho các loại dịch vụ khác nhau như doanh nghiệp, trường học, viện nghiên cứu và nhà điều hành nhượng quyền. Hiện tại, Hycons đang vận hành hơn 1,000 thẻ GPU và 2 trung tâm tại Hàn Quốc, với kế hoạch mở rộng lên 5 trung tâm trong tương lai. Hycons cũng đang thử nghiệm ứng dụng trong kinh doanh nhượng quyền cho các quán cà phê internet. Với nền tảng công nghệ tiên tiến, mức phí dịch vụ đám mây cạnh tranh và khả năng dịch vụ tự động, Hycons hướng tới trở thành nền tảng tính toán GPU hàng đầu thế giới.',
      organ4: 'ROCK Capital là một quỹ tiền điện tử chuyên nghiệp thành lập năm 2018, có trụ sở tại Hàn Quốc, tập hợp các chuyên gia về đầu tư tài chính, blockchain và phi tập trung hóa. ROCK hợp tác rộng rãi với các doanh nghiệp trong và ngoài nước, các dự án toàn cầu, chính phủ, tổ chức và các thực thể kinh tế khác, đã thực hiện thành công hơn 100 dự án và mở rộng ra nhiều lĩnh vực tài chính tương lai. Bằng cách củng cố hợp tác, tăng trưởng, kích hoạt và hợp tác, ROCK có nhiều kinh nghiệm và kiến thức chuyên môn trong các môi trường thị trường đa dạng. ROCK cam kết tạo ra lợi nhuận cao, mở rộng cơ hội đầu tư và tối đa hóa các hoạt động vốn để mang lại trải nghiệm cụ thể cho khách hàng.',
      organ5: 'Công ty đầu tư mạo hiểm này tập trung vào blockchain phi tập trung với công nghệ mới nổi, sẽ thúc đẩy cuộc cách mạng thứ tư và thứ năm. Công ty sở hữu kiến thức phong phú từ các lĩnh vực khác nhau, giúp họ nắm bắt cơ hội trong toàn bộ không gian web3 và ngoài ra. Họ cùng đầu tư với các thương hiệu nổi tiếng như Bloktopia, Animoca Brands và Stripe, và đã thiết lập mối quan hệ vững chắc với mạng lưới Singularity, hệ sinh thái Shiba và nhiều studio game. Những người sở hữu thẻ BTR-Army có thể sử dụng các dịch vụ của họ.'
    },
    cont15: {
      title: 'Hoạt động cộng đồng',
      
    }
  },
  coverage: {
    title: 'Bản đồ phân bố người dùng toàn cầu của phần mềm DeepLink',
    text: 'Tổng số người dùng DeepLink:'
  },
}